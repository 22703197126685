import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import Tool from "../models/tool";
import ToolService from "../services/tool.service";
import {Modal} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import {BASE_API_URL} from "../common/Constants";
import {useDispatch} from "react-redux";
import {tokenExpiredHandler} from "../store/actions/user";

const ToolEditModal = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        //interaction with parent
        showToolEditModal() {
            setTimeout(() => {
                setShow(true);
            }, 0)
        }
    }));

    const [tool, setTool] = useState(new Tool(0, '', '', 0, 0, 0, 0, '', null));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);
    const [categories, setCategories] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(null)
    //result count
    const [pageSize, setPageSize] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);

    const {t} = useTranslation();
    const dispatch = useDispatch();

    //send it from parent
    useEffect(() => {
        setTool(props.tool)
        if (props.tool.imagePath) {
            setImage(BASE_API_URL + props.tool.imagePath);
        }
    }, [props.tool])

    //send it from parent
    useEffect(() => {
        setCategories(props.categories)
    }, [props.categories])


    const editTool = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!tool.name || !tool.price) {
            return;
        }
        const formData = new FormData();
        formData.append("selectedFile", selectedFile);
        formData.append("tool", JSON.stringify(tool));

        ToolService.editTool(formData, tool.id).then(response => {
            //...
            props.onEdited(response.data);
            setShow(false);
            setSubmitted(false);

        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('MaxUploadSizeExceededException')) {
                    setErrorMessage(t('error_image_size_too_large'));
                }
                if (err.response.data.error_message.includes('The Token has expired')) {
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
            } else if (err.response.data.status === 403) {
                setErrorMessage(t('error_403'))

            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err);
            }
        });
    };

    //<input name="" onChange=(event) => handleChange(e)
    const handleChange = (e) => {
        const {name, value} = e.target;
        setTool((prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }));
        console.log(tool);
    };
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    }
    const closeButtonAction = () => {
        setShow(false)
        if (tool.imagePath) {
            setImage(BASE_API_URL + tool.imagePath);
        }
    }

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
        onImageChange(event);
    }
    return (
        <Modal show={show}>
            <form onSubmit={(e) => editTool(e)}
                  noValidate
                  className={submitted ? 'was-validated' : ''}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t('title_tool_edit')}</h5>
                    <button type="button" className="btn-close" onClick={() => setShow(false)}></button>
                </div>

                <div className="modal-body">

                    {errorMessage &&
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    }
                    {successMessage &&
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    }
                    {/*Name input*/}
                    <div className="form-group"><label htmlFor="name">{t('tool_name_label')}</label>
                        <input
                            type="text"
                            name="name"
                            placeholder={t('tool_name_placeholder')}
                            className="form-control"
                            required
                            value={tool.name}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="invalid-feedback">
                            Name is required
                        </div>
                    </div>
                    {/*Description Input*/}
                    <div className="form-group"><label htmlFor="description">{t('tool_description_label')}</label>
                        <textarea
                            type="text"
                            name="description"
                            placeholder={t('tool_description_placeholder')}
                            className="form-control"
                            value={tool.description}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    {/*Price input*/}
                    <div className="w-25 form-group"><label htmlFor="price">{t('tool_price_label')}</label>
                        <input
                            type="number"
                            name="price"
                            placeholder={t('tool_price_placeholder')}
                            className="form-control"
                            required
                            min="0.1"
                            max="5000"
                            step="0.1"
                            value={tool.price}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="invalid-feedback">
                            Price is required and should be greater than 0.
                        </div>
                    </div>
                    {/**/}
                    <div className="w-25 form-group"><label htmlFor="weight">{t('tool_weight_label')}</label>
                        <input
                            type="number"
                            name="weight"
                            placeholder={t('tool_weight_placeholder')}
                            className="form-control"
                            step="0.1"
                            value={tool.weight}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>

                    <div className="mt-3"><label htmlFor="category">{t('tool_select_category_label')}</label>
                        {tool.category &&
                            <select
                                name="category"
                                className="form-select"
                                onChange={(e) => handleChange(e)}
                                value={tool.category.id}
                            >
                                {categories.map((item, ind) =>
                                    <option value={item.id}>{item.name}</option>
                                )}
                            </select>
                        }


                        <div className="form-group">
                            {tool.imagePath &&
                                <img className="file-img mt-2 card-img-top" src={image}
                                     alt=""/>
                            }
                            <div className="fs-6">{t('change_image_label')}</div>
                            <input
                                type="file"
                                name="file"
                                onChange={(e) => handleFileSelect(e)}
                                className="w-50 btn btn-outline-dark form-control-file"/>
                        </div>
                    </div>

                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary"
                            onClick={() => setShow(false)}>{t('button_close')}</button>
                    <button type="submit" className="btn btn-primary">{t('button_save')}</button>
                </div>
            </form>
        </Modal>
    )
});

export {ToolEditModal};