import {SET_TOKENS,CLEAR_TOKENS} from "../types";

const tokensReducer = (state = {}, action) => {
    switch (action?.type) {
        case SET_TOKENS:
            localStorage.setItem('tokens', JSON.stringify(action?.payload));
            return action?.payload;
        case CLEAR_TOKENS:
            localStorage.removeItem('tokens')
            return null;
        default:
            return JSON.parse(localStorage.getItem('tokens'));
    };
};

export default tokensReducer;