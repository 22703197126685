// function ToolsAdminPage();
import {useEffect, useRef, useState} from "react";
import './Admin.css'
import {Link, NavLink, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes, faToolbox} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import ToolActual from "../../models/toolActual";
import ToolActualService from "../../services/tool.actual.service";
import Tool from "../../models/tool";
import ToolService from "../../services/tool.service";
import {ToolActualCreateModal} from "../../components/ToolActualCreateModal";
import {ToolActualEditModal} from "../../components/ToolActualEditModal";
import {ToolActualDeleteModal} from "../../components/ToolActualDeleteModal";
import RentalOrderToolsService from "../../services/rental.order.tools.service";
import {tokenExpiredHandler} from "../../store/actions/user";
import {DEFAULT_PAGE_SIZE} from "../../common/Constants";
import {Pagination} from "../../components/Pagination";


const ToolActualByToolIdAdminPage = () => {

    let {toolId} = useParams();

    const {t} = useTranslation();

    const navigate = useNavigate()


    const [toolActualList, setToolActualList] = useState([]);
    const [selectedToolActual, setSelectedToolActual] = useState(new ToolActual(0, '', '', 0, null));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [tool, setTool] = useState(new Tool(0, '', '', 0, 0,0, 0, '', null))
    //pagination
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const createComponent = useRef();
    const editComponent = useRef();
    const deleteComponent = useRef();
    const dispatch = useDispatch();

    const toolStatuses = [{
        name: t('tool_actual_status_ready'),
        value: 'READY'
    }, {name: t('tool_actual_status_needs_review'), value: 'NEEDS_REVIEW'}, {
        name: t('tool_actual_status_in_repair'),
        value: 'IN_REPAIR'
    }];

    useEffect(() => {
        ToolActualService.getToolActualListByToolId(toolId, pageNumber, pageSize).then((response) => {
            setToolActualList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('The Token has expired')) {
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }, []);

    const paginate = (pageNumber) => {
        ToolActualService.getToolActualListByToolId(toolId,pageNumber,pageSize).then((response) => {
            setToolActualList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty("error_message")) {
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }

    useEffect(() => {
        ToolService.getToolById(toolId).then((response) => {
            setTool(response.data);
        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('The Token has expired')) {
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }, []);

    const createToolActualRequest = () => {
        setSelectedToolActual(new ToolActual('', '', '', 0, null))
        createComponent.current?.showToolActualCreateModal();
    };

    const editToolActualRequest = (toolActual) => {
        setSelectedToolActual(toolActual);
        editComponent.current?.showToolActualEditModal();
    }
    const deleteToolActualRequest = (toolActual) => {
        setSelectedToolActual(toolActual);
        deleteComponent.current?.showToolActualDeleteModal();
    }

    const createToolActualWatcher = (toolActual) => {
        toolActual.available = true;
        const newList = toolActualList.concat(toolActual);
        setToolActualList(newList)
    };
    const showError = (message) => {
        setErrorMessage(message)
        setTimeout(() => {
            setErrorMessage('')
        }, 2000);
    }

    //very strange, does not work without creating new list and doing concat.
    const editToolActualWatcher = (toolActual) => {
        if (!toolActual.available) {
            toolActual.available = true;
        }
        if (toolActual.toolStatus === "NEEDS_REVIEW" || toolActual.toolStatus === "IN_REPAIR") {
            toolActual.available = false;
        }
        const index = toolActualList.findIndex((t) => t.id === toolActual.id);
        toolActualList[index] = toolActual;
        const newList = toolActualList.concat();
        setToolActualList(newList)
    };

    const checkStatus = (toolActual) => {
        const status = toolStatuses.find(s => toolActual.toolStatus === s.value);
        if (status) {
            return status.name;
        }
    }

    const deleteToolActual = async () => {
        ToolActualService.deleteTool(selectedToolActual.id).then(_ => {
            setToolActualList(toolActualList.filter(x => x.id !== selectedToolActual.id))
            setSuccessMessage('Tool Successfully Deleted');
            setErrorMessage('');
        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('ConstraintViolationException')) {
                    setErrorMessage(t('error_delete_tool_actual_constrains'));
                    setSuccessMessage('');
                }
                if (err.response.data.error_message.includes('The Token has expired')) {
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('');
                } else if (err.response.data.status === 403) {
                    setErrorMessage(t('error_403'))
                }
            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err)
            }

        });
    };
    const redirectToRentalOrder = (rentalOrder) => {
        navigate('/admin/orders/' + rentalOrder.id);
    }

    const checkOrderByToolActual = (toolActual) => {
        RentalOrderToolsService.getRentalOrderToolByToolActual(toolActual.id).then((response) => {
            if (response.data) {
                redirectToRentalOrder(response.data);
            }

        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes("NullPointerException")) {
                    setErrorMessage("There is no order with this too.")
                }
                if (err.response.data.error_message.includes('The Token has expired')) {
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
            } else {
                showError(t('unexpected_error_action'))
            }
        })
    }

    return (
        <div className="height-adjust container">
            <ul className="border-top col-xxl-6 col-xl-7 col-lg-8 col-sm-12 col-md-10 rounded-bottom mx-auto admin-bar nav nav-tabs justify-content-center bg-dark">
                <li className="nav-item">
                    <Link className="nav-link text-dark fw-bold active" to="/admin/tools">{t('tools_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link to="/admin/categories" className="nav-link text-light"
                          aria-current="page">{t('categories_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/customers">{t('customers_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/orders">{t('orders_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/users">{t('users_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/">{t('overview_tab')}</Link>
                </li>
            </ul>
            <div className="p-5">
                {errorMessage &&
                    <div className="alert alert-danger">
                        {errorMessage}
                    </div>
                }
                {successMessage &&
                    <div className="alert alert-success">
                        {successMessage}
                    </div>
                }
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-6">

                                <h3><FontAwesomeIcon icon={faToolbox}
                                                     className="ms-auto me-2"/>{tool.name}</h3>
                            </div>
                            <div className="col-6 text-end">
                                <Link to="/admin/tools" className="mx-2 btn btn-primary">
                                    {t('button_back_to_tools')}
                                </Link>
                                <button className="btn btn-primary" onClick={() => createToolActualRequest()}>
                                    {t('button_create_tool_actual')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table table-striped text-center">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t('header_serial')}</th>
                                <th scope="col">{t('header_status')}</th>
                                <th scope="col">{t('header_availability')}</th>
                                <th scope="col">{t('header_actions')}</th>
                            </tr>
                            </thead>
                            <tbody>

                            {toolActualList.map((item, ind) =>
                                <tr key={item.id}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.serialNumber}</td>
                                    <td>{checkStatus(item)}</td>

                                    {!item.available &&
                                        <td className="fs-4 text-danger"><FontAwesomeIcon icon={faTimes}
                                                                                          className="ms-4"/></td>
                                    }
                                    {item.available &&
                                        <td className="fs-4 text-success"><FontAwesomeIcon icon={faCheck}
                                                                                           className="ms-4"/></td>
                                    }
                                    <td className="w-25">
                                        <button className="btn btn-primary me-1"
                                                onClick={() => editToolActualRequest(item)}>
                                            {t('button_edit')}
                                        </button>
                                        <button
                                            onClick={() => deleteToolActualRequest(item)}
                                            className="btn btn-danger me-1">
                                            {t('button_delete')}
                                        </button>
                                        {(!item.available && (item.toolStatus == null || item.toolStatus === 'READY')) &&
                                            <button onClick={() => checkOrderByToolActual(item)}
                                                    className="btn btn-warning">
                                                {t('button_go_to_order')}
                                            </button>
                                        }
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {/*PAGINATION*/}
                        <Pagination pageSize={pageSize} totalItems={totalItems} paginate={paginate}/>
                        <div className='text-start'>
                            {totalPages > 1 &&
                                <div className='mx-2 d-inline-block'>{t('pagination_page')+pageNumber}</div>
                            }
                            <div className='mx-2 d-inline-block'>{t('pagination_total')+totalItems}</div>
                        </div>
                        {/*PAGINATION*/}
                    </div>
                </div>
            </div>
            <ToolActualCreateModal ref={createComponent} toolActual={selectedToolActual} onCreated={(p) => createToolActualWatcher(p)}/>
            <ToolActualEditModal ref={editComponent} toolActual={selectedToolActual}
                                 onEdited={(p) => editToolActualWatcher(p)}/>
            <ToolActualDeleteModal ref={deleteComponent} onConfirmed={(p) => deleteToolActual()}/>
        </div>
    );

};

export {ToolActualByToolIdAdminPage};