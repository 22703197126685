import './Footer.css'
import {BASE_API_URL, IMAGES_HOME_URL, phoneNumber} from "../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

const Footer = () => {

    const{t} = useTranslation();

    return (
            <footer className="py-3 footer mt-auto container-fluid px-5 footer-distributed bg-light text-dark">

            <div className="footer-left">

                <div>
                    <img className="logo" src={BASE_API_URL+IMAGES_HOME_URL+"logo.png"}></img>
                </div>

                <p className="footer-links">
                    <a href="/home" className="link-1">{t('home_page')}</a>
                    <a className="mx-1" href="/categories">{t('tools_page')}</a>
                    <a className="mx-1" href="/terms">{t('terms_page')}</a>
                </p>

                <p className="footer-company-name">{'Eligijaus Įrankiai © 2022 '+t('all_rights_reserved')}</p>
            </div>

            <div className="footer-center">

                <div>
                    <i className="fa fa-map-marker"></i>
                    <p><span>Sodų g. 22</span>Tauragės r., Skaudvilė</p>
                </div>

                <div>
                    <a href={"tel:" + phoneNumber} className="mt-2 nav-link"><FontAwesomeIcon className="ms-3" icon={faPhone}/>
                        {phoneNumber}</a>
                </div>

                <div>
                    <i className="fa fa-envelope"></i>
                    <p><a href="mailto:support@company.com">eligijaus.irankiai@gmail.com</a></p>
                </div>

            </div>

            <div className="footer-right">

                <p className="footer-company-about text-center">
                    <span>{t('working_hours')}</span>
                    <div className="row">
                        <div className="col text-end">
                            <p className="mb-0">{t('monday')}</p>
                            <p className="mb-0">{t('tuesday')}</p>
                            <p className="mb-0">{t('wednesday')}</p>
                            <p className="mb-0">{t('thursday')}</p>
                            <p className="mb-0">{t('friday')}</p>
                            <p className="mb-0">{t('saturday')}</p>
                            <p className="mb-0">{t('sunday')}</p>
                        </div>
                        <div className="col text-start">
                            <p className="mb-0">07:30 - 16:30</p>
                            <p className="mb-0">07:30 - 16:30</p>
                            <p className="mb-0">07:30 - 16:30</p>
                            <p className="mb-0">07:30 - 16:30</p>
                            <p className="mb-0">07:30 - 16:30</p>
                            <p className="mb-0">{t('closed')}</p>
                            <p className="mb-0">{t('closed')}</p>
                        </div>
                    </div>
                </p>

            </div>

        </footer>


    )
}
export {Footer};