import {combineReducers, createStore} from "redux";
import userReducer from "./reducers/user";
import tokensReducer from "./reducers/token";

const allReducers = combineReducers({
    user:userReducer,
    tokens:tokensReducer
});

const store = createStore(allReducers);

export default store;