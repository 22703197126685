export default class Tool {
    constructor(id,name,description,price,value,weight,category,imagePath,isAvailable){
        this.id = id;
        this.name = name;
        this.description= description;
        this.price = price;
        this.value = value;
        this.weight = weight;
        this.category=category;
        this.imagePath=imagePath;
    }
}
