import {useEffect, useRef, useState} from "react";
import './ToolByCategoryIdPage.css';
import {useParams} from "react-router-dom";
import ToolService from "../../services/tool.service";
import Tool from "../../models/tool";
import {ToolDetailsModal} from "../../components/ToolDetailsModal";
import {BASE_API_URL, DEFAULT_PAGE_SIZE} from "../../common/Constants";
import {Pagination} from "../../components/Pagination";
import {useTranslation} from "react-i18next";

const ToolByCategoryIdPage = () => {
    const [toolList, setToolList] = useState([]);
    const [selectedTool, setSelectedTool] = useState(new Tool(0, '', '', 0,0, 0, 0, '', null));
    //pagination
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const {t} = useTranslation();

    let {categoryId} = useParams();

    useEffect(() => {
        ToolService.getToolsByCategoryId(categoryId, pageNumber, pageSize).then((response) => {
            setToolList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        });
    }, []);

    const paginate = (pageNumber) => {
        ToolService.getToolsByCategoryId(categoryId, pageNumber, pageSize).then((response) => {
            setToolList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        });
    }

    const detailsComponent = useRef();

    const toolDetailsRequest = (tool) => {
        setSelectedTool(tool);
        detailsComponent.current?.showToolDetailsModal();
    }


    return (
        <div className="height-adjust">
            <header className="bg-dark py-1">
                <div className="container px-4 px-lg-5 my-2">
                    <div className="text-center text-white">
                        {toolList.length > 0 &&
                            <h1 className="display-4 fw-bolder">{toolList[0].category.name}</h1>
                        }
                        {toolList.length < 1 &&
                            <h1 className="display-4 fw-bolder">No items</h1>

                        }
                        <p className="lead fw-normal text-white-50 mb-0">{t('tools_by_category_header_details')}</p>
                    </div>
                </div>
            </header>
            <div className="container px-4 px-lg-5 mt-5">

                <div className="row gx-4 gx-lg-5 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-4 justify-content-center">
                    {toolList.map((item, ind) =>
                        <div className="col mb-3">
                            <button onClick={() => toolDetailsRequest(item)} className="mx-auto text-center btn btn-outline-dark p-0 card h-100">

                                {!item.imagePath &&
                                    <img className="card-img-top" src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                                         alt="..."/>
                                }
                                {item.imagePath &&
                                    <img className="card-img-top" src={BASE_API_URL + item.imagePath}
                                         alt=""></img>
                                }

                                <div className="w-100 card-body p-4">
                                    {item.isAvailable &&
                                        <div className="">
                                            <h5 className="fw-bolder">{item.name}</h5>
                                            {item.price + ' €/ '+t('rent_day')}
                                        </div>
                                    }
                                    {!item.isAvailable &&
                                        <div className="text-center">
                                            <h5 className="fw-bolder">{item.name}</h5>
                                            {t('button_tool_status_rented')}
                                        </div>
                                    }
                                    {/*<div>{item.description}</div>*/}

                                </div>
                            </button>
                        </div>
                    )}
                </div>
                {/*PAGINATION*/}
                <div className='paging'>
                    <Pagination pageSize={pageSize} totalItems={totalItems} paginate={paginate}/>
                    {totalPages > 1 &&
                        <div className='mx-2 d-inline-block'>{'Page: ' + pageNumber}</div>
                    }
                </div>
                {/*PAGINATION*/}
            </div>
            <ToolDetailsModal ref={detailsComponent} tool={selectedTool}/>
        </div>
    )

}

export {ToolByCategoryIdPage};