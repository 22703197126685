import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import RentalOrder from "../../models/order";
import {useTranslation} from "react-i18next";
import RentalOrderService from "../../services/rental.order.service";
import Customer from "../../models/customer";
import RentalOrderToolsService from "../../services/rental.order.tools.service";
import DatePicker from "react-datepicker";
import './Order.css'
import "react-datepicker/dist/react-datepicker.css";
import CategoryService from "../../services/category.service";
import ToolService from "../../services/tool.service";
import ToolActualService from "../../services/tool.actual.service";
import RentalOrderTool from "../../models/rental.order.tool";
import {VAT} from "../../common/Constants";
import Payment from "../../models/payment";
import {PaymentCreateModal} from "../../components/PaymentCreateModal";
import PaymentService from "../../services/payment.service";
import {CustomerDetailsModal} from "../../components/CustomerDetailsModal";
import {tokenExpiredHandler} from "../../store/actions/user";
import {useDispatch} from "react-redux";
import {RentalOrderDocument} from "../../components/RentalOrderDocument";
import {TotalOverrideModal} from "../../components/TotalOverrideModal";


const OrderByIdPage = () => {

    const {orderId} = useParams();

    const [customer, setCustomer] = useState(new Customer(0, '', '', '', 0, '', null, 0, 0, 0, 0, null));
    const [toolActualList, setToolActualList] = useState([]);
    const [payment, setPayment] = useState(new Payment(0, 0, 0, '', ''));
    const [paymentList, setPaymentList] = useState([]);
    const [days, setDays] = useState(1);
    const [toolList, setToolList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [rentalOrderToolList, setRentalOrderToolList] = useState([]);
    const [rentStart, setRentStart] = useState(new Date());
    const [rentEnd, setRentEnd] = useState(new Date());
    const [rentalOrder, setRentalOrder] = useState(new RentalOrder(0, new Customer(0, '', '', '', 0, '', null, 0, 0, 0, 0, null), 0, 0, 0, '', null, 0, 0, 0, 0));
    const [view, setView] = useState('category');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    //pagination
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const orderStatuses = [{
        name: t('rental_order_status_open'),
        value: "OPEN"
    }, {name: t('rental_order_status_in_progress'), value: "IN_PROGRESS"}, {
        name: t('rental_order_status_closed'),
        value: "CLOSED"
    }];

    const paymentMethods = [{name: t('payment_method_bank_transfer'), value: "BANK_TRANSFER"},
        {name: t('payment_method_cash'), value: 'CASH'},
        {name: t('payment_method_credit'), value: 'CREDIT'},
        {name: t('payment_method_refund'), value: 'REFUND'}];

    const overrideComponent = useRef();
    const paymentOverrideRequest = (rentalOrder) => {
        setRentalOrder(rentalOrder);
        overrideComponent.current?.showTotalOverrideModal();
    }

    const createComponent = useRef();
    const createPaymentRequest = (payment) => {
        setPayment(new Payment(0, 0, rentalOrder, '', ''));
        createComponent.current?.showPaymentCreateModal();
    }

    const detailsComponent = useRef();
    const customerDetailsRequest = (customer) => {
        setCustomer(customer);
        detailsComponent.current?.showCustomerDetailsModal();
    }

    const documentComponent = useRef();
    const documentRequest = () => {
        documentComponent.current?.showRentalOrderDocument();
    }

    const errorHandlerWithReload = (err) => {
        if (err.response.data.hasOwnProperty("error_message")) {
            if (err.response.data.error_message.includes('The Token has expired')) {
                tokenExpiredHandler(dispatch);
                setSuccessMessage(t('success_refresh_access_token_reload'));
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
            if (err.response.data.error_message.includes('Only admin')) {
                showError(t('error_remove_item_in_progress'));
            }
            if (err.response.data.error_message.includes('InvalidRentalOrderDateException')) {
                showError(t('error_invalid_date'));
            }
            if (err.response.data.error_message.includes('EntityNotFoundException')) {
                navigate('/admin/orders');
            }
            if (err.response.data.error_message.includes('Failed to remove/add')) {
                showError(t('error_cannot_add_items_closed_order'));
            }
            if (err.response.data.error_message.includes('Closed orders cannot be edited')) {
                showError('order_closed_cannot_edit_error');
            }
        } else if (err.response.data.status === 403) {
            showError(t('error_403'))
            console.log(err)

        } else {
            console.log(err)
            showError(t('unexpected_error_action'));
        }
    }


    //when creating new order only.
    useEffect(() => {
        RentalOrderService.getOrderById(orderId).then((response) => {
            setRentalOrder(response.data);
            setDays(response.data.days);
            getDates(response.data);
        }).catch((err) => {
            errorHandlerWithReload(err);
        });
    }, []);

    const calculateVAT = (total) => {
        var vatvalue = VAT / 100 + 1;
        var vat = total - total / vatvalue
        var vatRounded = Math.round(vat * 100) / 100;
        return vatRounded;
    }

    const getDates = (rentalOrder) => {
        if (rentalOrder.rentStart) {
            var start = new Date(rentalOrder.rentStart);
            setRentStart(start);
        }
        if (rentalOrder.rentEnd) {
            var end = new Date(rentalOrder.rentEnd);
            setRentEnd(end);
        }
    }

    useEffect(() => {
        RentalOrderToolsService.getRentalOrderToolByOrderId(orderId).then((response) => {
            setRentalOrderToolList(response.data);
        }).catch((err) => {
            errorHandlerWithReload(err);
        })
    }, []);

    useEffect(() => {
        PaymentService.getPaymentsByOrderId(orderId).then((response) => {
            setPaymentList(response.data);
        }).catch((err) => {
            errorHandlerWithReload(err)
        });
    }, []);

    useEffect(() => {
        CategoryService.getAllCategories(pageNumber, pageSize).then((response) => {
            setCategoryList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        });
    }, []);

    const categoryClickHandler = (categoryId) => {
        setView('tool')
        ToolService.getToolsByCategoryId(categoryId, pageNumber, pageSize).then((response) => {
            setToolList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        });
    }
    const toolClickHandler = (toolId) => {
        setView('actual')
        ToolActualService.getToolActualListByToolId(toolId, pageNumber, pageSize).then((response) => {
            setToolActualList(response.data.content);
        });

    }

    const backToCategoriesHandler = () => {
        setView('category');
    }
    const backToToolsHandler = () => {
        setView('tool');
    }
    const concatOrderTool = (rentalOrder, toolActual) => {
        const newList = rentalOrderToolList.concat(new RentalOrderTool(null, rentalOrder, toolActual));
        setRentalOrderToolList(newList)
        if (newList.length < 0) {
            setRentalOrderToolList([]);
        }
    }

    const addItemToOrderHandler = (toolActual) => {
        RentalOrderService.addItem(orderId, toolActual).then(response => {
            setRentalOrder(response.data);
            concatOrderTool(response.data, toolActual);
            setView('category')
        }).catch((err) => {
            errorHandlerWithReload(err);
        });
    };
    const removeItem = (toolActual) => {
        RentalOrderService.removeItem(orderId, toolActual).then(response => {
            setRentalOrderToolList(rentalOrderToolList.filter(x => x.toolActual.id !== toolActual.id));
            setRentalOrder(response.data);
        }).catch((err) => {
            errorHandlerWithReload(err)
        });
    }
    const adjustDates = () => {
        RentalOrderService.adjustDates(orderId, rentalOrder).then(response => {
            setRentalOrder(response.data);
            if (response.data.days) {
                setDays(response.data.days);
            }
        }).catch((err) => {
            errorHandlerWithReload(err);
        });
    }
    const orderProceedButtonHandler = () => {
        var status = rentalOrder.rentalOrderStatus;
        rentalOrder.rentalOrderStatus = 'IN_PROGRESS';
        RentalOrderService.statusChange(orderId, rentalOrder).then(response => {
            setRentalOrder(response.data);
        }).catch((err) => {
            rentalOrder.rentalOrderStatus = status;
            errorHandlerWithReload(err);
        })
    }
    const closeOrderButtonHandler = () => {
        var status = rentalOrder.rentalOrderStatus;
        rentalOrder.rentalOrderStatus = "CLOSED";
        RentalOrderService.statusChange(orderId, rentalOrder).then(response => {
            setRentalOrder(response.data)
        }).catch((err) => {
            rentalOrder.rentalOrderStatus = status;
            errorHandlerWithReload(err);
        })
    }

    const showError = (message) => {
        setErrorMessage(message)
        setTimeout(() => {
            setErrorMessage('')
        }, 5000);
    }
    const showSuccess = (message) => {
        setSuccessMessage(message)
        setTimeout(() => {
            setSuccessMessage('')
        }, 5000);
    }
    const onRentEndChange = (rentEnd) => {
        setRentEnd(rentEnd);
        rentalOrder.rentEnd = rentEnd;
        rentalOrder.rentStart = rentStart;
        adjustDates()
    }
    const onRentStartChange = (rentStart) => {
        setRentStart(rentStart);
        rentalOrder.rentStart = rentStart;
        rentalOrder.rentEnd = rentEnd;
        adjustDates()
    }
    const getRentalOrderStatus = (rentalOrder) => {
        var status = orderStatuses.find(x => x.value === rentalOrder.rentalOrderStatus);
        return status.name;
    }

    const createPaymentWatcher = (payment) => {
        if (payment.paymentMethod === 'REFUND' && rentalOrder.rentalOrderStatus === 'CLOSED') {
            rentalOrder.rentalOrderStatus = "IN_PROGRESS";
        }
        const newList = paymentList.concat(payment);
        setPaymentList(newList)
    };
    const overrideAmountWatcher = (order) => {
        if (order.totalPrice >0) {
            rentalOrder.totalPrice = order.totalPrice;
        }
        setRentalOrder(order);
    };

    const calculatePaymentDue = () => {
        var totalPayments = 0;
        var totalRefunds = 0;
        if (paymentList.length > 0) {
            paymentList.forEach((payment) => {
                if (payment.paymentMethod === 'REFUND') {
                    totalRefunds += payment.amount;
                }
                if (payment.paymentMethod !== 'REFUND') {
                    totalPayments += payment.amount;
                }
            })
        }
        return rentalOrder.totalPrice - (totalPayments - totalRefunds);
    }

    const getPaymentMethod = (payment) => {
        let method = paymentMethods.find(x => x.value === payment.paymentMethod);
        if (method) {
            return method.name
        } else {
            return '';
        }
    }

    return (

        <div className="non-printable mt-5 container height-adjust justify-content-center position-relative">
            {errorMessage &&
                <div className="error-message alert alert-danger position-absolute w-100 text-center">
                    {errorMessage}
                </div>
            }
            {successMessage &&
                <div className="error-message alert alert-success position-absolute w-100 text-center">
                    {successMessage}
                </div>
            }
            <div className="row">
                <div className="p-0 card col-xxl-4">
                    {rentalOrder.rentalOrderStatus === 'OPEN' &&
                        <div className="row p-0 m-0 rounded-top rounded-5 border border-1 border-danger bg-danger">
                            <div className="col ps-2">{t('order_details')}</div>
                            <div className="col">{t('order_status') + getRentalOrderStatus(rentalOrder)}</div>
                        </div>
                    }
                    {rentalOrder.rentalOrderStatus === 'IN_PROGRESS' &&
                        <div className="row p-0 m-0 rounded-top rounded-5 border border-1 border-info bg-info">
                            <div className="col ps-2">{t('order_details')}</div>
                            <div className="col">{t('order_status') + getRentalOrderStatus(rentalOrder)}</div>
                        </div>
                    }
                    {rentalOrder.rentalOrderStatus === 'CLOSED' &&
                        <div
                            className="row p-0 m-0 rounded-top rounded-5 border border-1 border-secondary bg-secondary">
                            <div className="col ps-2">{t('order_details')}</div>
                            <div className="col">{t('order_status') + getRentalOrderStatus(rentalOrder)}</div>
                        </div>
                    }

                    <div className="text-light card-header bg-dark">
                        <div className="row">
                            <div className="col">
                                {t('order_id') + orderId}
                            </div>
                            <div className="col">
                                <button className="btn btn-sm btn-warning"
                                        onClick={() => customerDetailsRequest(rentalOrder.customer)}>
                                    {t('order_customer') + rentalOrder.customer.name}
                                </button>
                            </div>
                        </div>

                    </div>
                    <div className="card-body p-1 text-center">
                        {view === 'category' &&
                            <div className="divas ">
                                {categoryList.map((item, ind) =>
                                    <button
                                        onClick={() => categoryClickHandler(item.id)}
                                        className="col-5 menu-button shadow rounded rounded-5 mb-1 mx-2 mt-0 bnt btn-outline-dark">
                                        {item.name}
                                    </button>
                                )}

                            </div>
                        }
                        {view === 'tool' &&
                            <div className="divas ">
                                <button onClick={() => backToCategoriesHandler()}
                                        className="shadow rounded rounded-5 col-11 mb-1 btn btn-outline-dark">Back to
                                    Categories
                                </button>
                                {toolList.map((item, ind) =>
                                    <button
                                        onClick={() => toolClickHandler(item.id)}
                                        className="shadow rounded rounded-5 col-5 menu-button mb-1 mx-2 mt-0 bnt btn-outline-dark">
                                        {item.name}
                                    </button>
                                )}

                            </div>
                        }
                        {view === 'actual' &&
                            <div className="divas ">
                                <button onClick={() => backToToolsHandler()}
                                        className="shadow rounded rounded-5 col-11 mb-1 btn btn-outline-dark">Back
                                    to Tools
                                </button>
                                {toolActualList.filter(tool => tool.available).map((item, ind) =>
                                    <button
                                        onClick={() => addItemToOrderHandler(item)}
                                        className="shadow rounded rounded-5 col-5 menu-button mb-1 mx-2 mt-0 bnt btn-outline-dark">
                                        {item.serialNumber}
                                    </button>
                                )}

                            </div>
                        }


                    </div>

                </div>
                <div className="p-0 card col-xxl-8">
                    <div className="p-0 m-0 row border rounded-top rounded-5 border-1 border-light bg-dark">
                        <div className="ps-3 col-6 text-light">{t('order_datepicker_rent_start')}</div>
                        <div className="ps-2 col-4 text-light">{t('order_datepicker_rent_end')}</div>
                        <button className="btn btn-sm btn-warning ps-2 col-2"
                                onClick={() => documentRequest()}>Sutartis
                        </button>
                    </div>
                    <div className="text-light card-header bg-dark">
                        <div className="row">
                            <div className="date-picker-div p-0 m-0 text-primary col">
                                <DatePicker
                                    selected={rentStart}
                                    onChange={(rentStart) => onRentStartChange(rentStart)}
                                    showTimeSelect
                                    shouldCloseOnSelect={true}
                                    timeIntervals={60}
                                    dateFormat="yyy/MM/dd HH:mm"
                                    timeFormat="HH:mm"
                                />
                            </div>
                            <div className="date-picker-div text-primary col">
                                <DatePicker
                                    selected={rentEnd}
                                    onChange={(rentEnd) => onRentEndChange(rentEnd)}
                                    showTimeSelect
                                    shouldCloseOnSelect={true}
                                    timeIntervals={60}
                                    dateFormat="yyyy/MM/dd HH:mm"
                                    timeFormat="HH:mm"
                                />
                            </div>
                        </div>

                    </div>
                    <div className="card-body">
                        <table className="mb-0 table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t('order_table_tool_name')}</th>
                                <th scope="col">{t('order_table_serial')}</th>
                                <th scope="col">{t('order_table_price')}</th>


                                {rentalOrder.rentalOrderStatus !== 'CLOSED' &&
                                    <th className="text-center" scope="col">{t('order_table_action')}</th>
                                }
                            </tr>
                            </thead>
                            <tbody>


                            {rentalOrderToolList.map((item, ind) =>

                                <tr key={item.id}>
                                    <th scope="row">{ind + 1}</th>
                                    <td>{item.toolActual.tool.name}</td>
                                    <td>{item.toolActual.serialNumber}</td>
                                    <td>{item.toolActual.tool.price + ' €'}</td>

                                    {rentalOrder.rentalOrderStatus !== 'CLOSED' &&
                                        <td className="text-center">
                                            <button onClick={() => removeItem(item.toolActual)}
                                                    className="btn btn-danger">
                                                {t('button_remove_item')}
                                            </button>
                                        </td>
                                    }
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {/*Rental Order Bottom Summary Information*/}
                        {rentalOrderToolList.length > 0 &&
                            <div className="order-bottom-information">
                                <div className="fw-bold sum-line pt-0 pe-0 mt-0 text-center">
                                <span>{t('order_total') + rentalOrder.totalPrice + ' € | '}<span
                                    className="text-muted, fst-italic">{t('order_vat') + '(' + VAT + '%): ' + calculateVAT(rentalOrder.totalPrice) + ' €'}</span></span>
                                    <span className="">{' | ' + t('order_days') + days}</span>
                                </div>
                                <div className="fw-bold sum-line pt-0 pe-0 mt-0 text-center">
                                    {calculatePaymentDue() === 0 && (rentalOrder.rentalOrderStatus === 'IN_PROGRESS' || rentalOrder.rentalOrderStatus === 'CLOSED') &&
                                        <span
                                            className="btn btn-success display-6 text-uppercase fw-bold">{t('order_fully_paid')}</span>
                                    }
                                    {calculatePaymentDue() !== 0 &&
                                        <span>{t('order_payment_due') + calculatePaymentDue() + ' €'}</span>
                                    }
                                </div>
                            </div>
                        }
                        <div className="text-center row">
                            {(rentalOrderToolList.length > 0 && rentalOrder.rentalOrderStatus === 'OPEN') &&

                                <div className="col m-1">
                                    <button
                                        onClick={() => orderProceedButtonHandler()}
                                        className="w-100 shadow btn btn-primary"
                                        type="button">{t('button_proceed')}</button>
                                </div>
                            }
                            {((rentalOrder.rentalOrderStatus === 'IN_PROGRESS' && calculatePaymentDue() === 0) || ((rentalOrder.rentalOrderStatus === 'OPEN' || rentalOrder.rentalOrderStatus === 'IN_PROGRESS') && rentalOrderToolList.length < 1)) &&
                                <div className="col m-1">
                                    <button
                                        onClick={() => closeOrderButtonHandler()}
                                        className="w-100 shadow btn btn-danger"
                                        type="button">{t('button_close_order')}</button>
                                </div>
                            }
                            {(rentalOrder.rentalOrderStatus === 'IN_PROGRESS' || rentalOrder.rentalOrderStatus === 'CLOSED') && rentalOrderToolList.length > 0 &&
                                <div className="col m-1">
                                    <div className="row">
                                        <div className="col">
                                            <button
                                                onClick={() => createPaymentRequest()}
                                                className="w-100 shadow btn btn-primary"
                                                type="button">{t('button_take_payment')}
                                            </button>
                                        </div>
                                        {calculatePaymentDue() !== 0 &&
                                        <div className="col-4">
                                            <button
                                                onClick={() => paymentOverrideRequest(rentalOrder)}
                                                className="w-100 shadow btn btn-primary"
                                                type="button">{t('button_total_override')}
                                            </button>
                                        </div>
                                        }

                                    </div>
                                </div>
                            }
                        </div>

                    </div>

                    {/*Collapse Info for payments below*/}
                    {paymentList.length > 0 &&
                        < div>
                            < button className="btn btn-link" type="button" data-bs-toggle="collapse"
                                     data-bs-target="#collapseExample" aria-expanded="false"
                                     aria-controls="collapseExample">
                                {t('order_show_all_payments')}
                            </button>
                            <div className="collapse" id="collapseExample">
                                <div className="mt-3 card card-body">
                                    <table className="text-center mb-0 table table-striped">
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{t('table_header_payment_method')}</th>
                                            <th scope="col">{t('table_header_payment_amount')}</th>
                                            <th scope="col">{t('table_header_date_time')}</th>
                                            <th scope="col">{t('table_header_payment_taken_by')}</th>

                                        </tr>
                                        </thead>
                                        <tbody>


                                        {paymentList.map((item, ind) =>

                                            <tr key={item.id}>
                                                <th scope="row">{item.id}</th>
                                                <td>{getPaymentMethod(item)}</td>
                                                {item.paymentMethod === 'REFUND' &&
                                                    <td>{'- ' + item.amount + ' €'}</td>
                                                }
                                                {item.paymentMethod !== 'REFUND' &&
                                                    <td>{item.amount + ' €'}</td>
                                                }
                                                <td>{(item.createdAt).substring(0, 10) + " | " + (item.createdAt).substring(11, 16)}</td>
                                                <td>{item.createdBy}</td>

                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    }
                    {/*Collapse Info for payments above*/}

                </div>
            </div>
            <CustomerDetailsModal ref={detailsComponent} customer={customer}/>
            <PaymentCreateModal ref={createComponent} onCreated={(p) => createPaymentWatcher(p)}/>
            <TotalOverrideModal ref={overrideComponent} rentalOrder={rentalOrder} onOverride={(p) => overrideAmountWatcher(p)}/>
            <RentalOrderDocument ref={documentComponent} days={days} rentalOrderToolList={rentalOrderToolList}
                                 rentalOrder={rentalOrder}/>


        </div>

    )
}
export {OrderByIdPage}