import './terms.css'
import {BASE_API_URL, IMAGES_HOME_URL} from "../../common/Constants";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Terms = () => {
    const {t} = useTranslation();


    return (
        <main className="height-adjust">
            <header className="bg-dark py-1">
                <div className="container px-4 px-lg-5 my-2">
                    <div className="text-center text-white">
                        <h1 className="display-4 fw-bolder">Nuomos Sąlygos</h1>
                        <p className="lead fw-normal text-white-50 mb-0">Prieš pasinaudojant mūsų teikiamomis
                            paslaugomis, turite susipažinti su įrankių nuomos sąlygomis</p>
                    </div>
                </div>
            </header>
            <div className="mx-auto m-5 col-8 container">
                <h3>
                    Norint išsinuomoti įrangą būtina pasirašyti nuomos sutartį bei pateikti šiuos dokumentus:
                </h3>
                <div className="mx-auto col-10">
                    <h4>
                        Fiziniams asmenims
                    </h4>
                    <ul>
                        <li>Vieno asmens dokumentų (pvz. pasas, vair. pažymėjimas, techn. mašinos pasas.)</li>
                        <li>Kiekvienu konkrečiu nuomos atveju užpildomas perdavimo aktas.</li>
                        <li>Nuomotojas visada pasilieka teisę imti užstatą už išnuomotus instrumentus.</li>
                    </ul>
                    <h4>
                        Juridiniams asmenims
                    </h4>
                    <ul>
                        <li>Įmonės įgaliojimą, patvirtintą direktoriaus parašu ir spaudu.</li>
                        <li>Įmonės registracijos pažymėjimo kopiją.</li>
                        <li>Asmens tapatybę patvirtinantį dokumentą (pasą arba vairuotojo pažymėjimą ir darbuotojo
                            pažymėjimą).
                        </li>
                    </ul>
                </div>
                <h3>
                    Nuomos Objektas
                </h3>
                <div className="mt-3 mb-3 mx-auto col-10">
                    Statybos įrankių nuoma. Nuomos terminas prasideda nuo įrenginių perdavimo nuomininkui dienos arba
                    nuo dienos, kai nuomininkas gauna galimybę paimti įrenginius, ir baigiasi kai įrenginiai yra
                    grąžinami į nuomotojo sandėlį, jeigu nebuvo susitarta kitaip. Jeigu susitariama dėl konkretaus
                    nuomos termino, faktinis nuomos terminas turi atitikti terminą nustatytą sutartyje. Sutartyje
                    nurodyto nuomos termino užtęsimas be nuomotojo leidimo gali būti traktuojamas kaip neteisėtas turto
                    valdymas. Nuomininkas privalo grąžinti išsinuomotus įrenginius ir mechanizmus nuomotojui iš karto po
                    nuomos termino pasibaigimo. Grąžinami įrenginiai turi būti švarūs ir tokios pat būklės, kokios jie
                    buvo nuomininko gauti, atsižvelgiant į normalų susidėvėjimą, įrenginiai ir mechanizmai turi būti
                    grąžinti į tą pačią vietą iš kur jie buvo gauti, nebent raštu buvo susitarta kitaip. Jeigu
                    nuomininkas negrąžina nuomos objekto, jis privalo pilnai atlyginti nuomotojui įrenginių ar
                    mechanizmų paieškos ir pervežimo išlaidas.
                </div>
                <h3>
                    Nuomos mokesčio dydis, transportavimo paslaugos ir jų mokėjimai
                </h3>
                <div className="mt-3 mb-3 mx-auto col-10">
                    Nuomos mokesčio dydis, transportavimo paslaugos yra nustatomos pagal nuomotojo kainininką, jeigu
                    nesusitariama kitaip. Į nuomos mokestį neįeina planavimo, įrengimo sumontavimo ir išmontavimo,
                    kontrolės, pakrovimo ir iškrovimo darbo vietoje išlaidos. Nuomotojas pasilieka sau teisę keisti
                    kainininke nurodytas kainas. Atsiskaitoma nacionaline valiuta (eurais) grynais pinigais Nuomotojo
                    kasoje ,išankstiniu pavedimu į atsiskaitomąją sąskaitą banke, arba sąskaita faktūra (juridiniams
                    asmenims pasirašius bendradarbiavimo-nuomos sutartį), atsiskaitymo terminas nurodytas sąskaitoje
                    faktūroje. Nuomininkui praleidus nuomos sutartyje numatytą terminą sumokėti nuomos mokestį,
                    nuomotojas turi teisę reikalauti, kad nuomininkas mokėtų 0,2% nesumokėtos sumos dydžio delspinigius
                    už kiekvieną pavėluotą dieną.
                </div>
                <h3>
                    Įrenginių paėmimas ir reikalavimas pranešti apie defektus
                </h3>
                <div className="mt-3 mb-3 mx-auto col-10">
                    Nuomininkas privalo nedelsiant patikrinti gaunamų įrenginių kiekį, kokybę ir būklę. Nuomininkas yra
                    atsakingas už mechanizmų ir įrenginių eksploataciją: tiekiamą energiją, tepimo medžiagas, alyvą,
                    išlaikymą ir reikiamų specialistų parūpinimą, jeigu raštu nesusitariama kitaip. Nuomotojas padengia
                    visus įrenginių ir mechanizmų remonto, susijusio su normaliųjų susidėvėjimu, išlaidas. Nuomotojo
                    sąskaita vykdomi remonto darbai ir darbai, susiję su tinkamu nuomos objekto išlaikymu, atliekami
                    įprastu darbo laiku. Apie įvykusį darbo metu įrankio gedimą nedelsiant pranešti nuomotojui.
                </div>
                <h3>
                    Atsakomybė už nuomos objektą ir jo sukeltą žalą
                </h3>
                <div className="mt-3 mb-3 mx-auto col-10">
                    Išskyrus natūralų susidėvėjimą, nuomininkas atsako už visus nuostolius ar nuomos objekto praradimus
                    nuomos laikotarpiu. Nuomininkas privalo atlyginti nuomos laikotarpiu sugadintų arba prarastų
                    įrenginių vertę. Nuomotojas turi teisę atskirai reikalauti sumokėti išlaidas, susijusias su
                    įrenginių išvalymu ar sutvarkymu. Nuomotojas neatsako už tiesioginę ar netiesioginę žalą,
                    atsiradusią nuomininkui ar tretiesiems asmenims dėl nuomos laikotarpiu nuomininko naudojamų
                    įrenginių, nebent nuomotojas yra pripažįstamas kaltu dėl neatsargiai padarytų veiksmų, sukėlusių
                    minėtą žalą. Nuomotojas yra atsakingas už žalą, atsiradusią dėl klaidos eksploatacijos ar montavimo
                    taisyklėse, bet tik tuo atveju, kai žala tiesiogiai ir betarpiškai atsirado dėl minėtos klaidos.
                    Jeigu nuomotojas atlygina žalą trečiajam asmeniui, kai pagal šio straipsnio reikalavimus jis
                    neatsako už padarytą žalą, jis gauna teisę išreikalauti su žalos atlyginimu susijusias išlaidas iš
                    nuomininko regreso tvarka.
                </div>
                <h3>
                    Darbo sauga
                </h3>
                <div className="mt-3 mb-3 mx-auto col-10">
                    Nuomininkas privalo tikrinti darbo vietas, kur naudojami išsinuomoti įrenginiai, vykdyti atitinkamą
                    kontrolę ir kitus reikalavimus, nustatytus darbo saugą reguliuojančiuose teisės aktuose. Nuomininkas
                    visiškai atsako už traumas, sužalojimus ir kitas sunkias pasekmes, atsiradusias dėl netinkamo
                    įrengimo eksploatavimo ar darbo saugos taisyklių pažeidimo. Nuomotojas neatsako už nuostolius,
                    kuriuos nuomininkas patyrė dėl nuomojamų įrengimų ar mechanizmų gedimo, o taip pat už nuostolius,
                    kurie atsirado nuomininkui dėl minėtų priežasčių priverstinai nutraukus darbą.
                </div>
                <h3>
                    Kitos sąlygos
                </h3>
                <div className="mt-3 mb-3 mx-auto col-10">
                    Be atskiro susitarimo, minėtos nuomos sąlygos galioja ir visam kitam išsinuomotam turtui, kuris, be
                    sutartyje paminėto, yra perduodamas nuomininkui nurodytą ar nugabenamas į vietą pagal susitarimą. Jeigu
                    dėl nenugalimos jėgos (Force Majeure) ar kitų panašių nuo nuomotojo nepriklausančių aplinkybių
                    nuomotojas nevykdo nuomos sąlygų arba jei nuomos sąlygas vykdyti dėl tokių aplinkybių pasidaro
                    neįmanoma, arba jeigu jos vykdymas ilgam laikui sustabdomas, nuomotojas neatsako už nuomininko
                    patirtą žalą. Be šioje sutartyje numatytų sąlygų, šalys vadovaujasi Lietuvos Respublikos įstatymais.
                    Visi ginčai ir nesutarimai sprendžiami abipusiai naudingu pagrindu. Šalims nesusitarus ginčas
                    sprendžiamas teismine tvarka vadovaujantis Lietuvos Respublikos įstatymais. Sutartis surašyta dviem
                    lygiaverčiais egzemplioriais, kurių vienas tenka nuomotojui, o kitas nuomininkui.
                </div>


            </div>
        </main>
    )

}

export {Terms};