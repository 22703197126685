import './SettingsPage.css';
import {useTranslation} from "react-i18next";

const SettingsPage = () => {

    const {t} = useTranslation();

    return (
        <div className="height-adjust container container light-style flex-grow-1 container-p-y">
            <h4 className="font-weight-bold py-3 mb-4">
                {t('settings_page')}
            </h4>
            <div className="row no-gutters row-bordered row-border-light">
                <div className="col-md-3 pt-0">
                    <div className="list-group list-group-flush account-settings-links"><a
                        className="list-group-item list-group-item-action active" data-toggle="list"
                        href="#account-general">General</a> <a className="list-group-item list-group-item-action"
                                                               data-toggle="list" href="#account-change-password">Change
                        password</a> <a className="list-group-item list-group-item-action" data-toggle="list"
                                        href="#account-info">Info</a> <a
                        className="list-group-item list-group-item-action" data-toggle="list"
                        href="#account-social-links">Social links</a> <a
                        className="list-group-item list-group-item-action" data-toggle="list"
                        href="#account-connections">Connections</a> <a
                        className="list-group-item list-group-item-action" data-toggle="list"
                        href="#account-notifications">Notifications</a></div>
                </div>
            </div>
        </div>
)

}

export
    {
        SettingsPage
    }
;