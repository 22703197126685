import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Modal} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import Customer from "../models/customer";
import CustomerService from "../services/customer.service";
import {tokenExpiredHandler} from "../store/actions/user";
import {useDispatch} from "react-redux";

const CustomerEditModal = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        //interaction with parent
        showCustomerEditModal() {
            setTimeout(() => {
                setShow(true);
            }, 0)
        }
    }));
    const [customer, setCustomer] = useState(new Customer(0, '', '', '', 0, '', null, 0, 0, 0, 0, null));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);

    const {t} = useTranslation();
    const dispatch = useDispatch();

    //send it from parent
    useEffect(() => {
        setCustomer(props.customer)
    }, [props.customer])


    const editCustomer = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!customer.name || !customer.phone) {
            setErrorMessage(t('error_customer_edit_required_fields'))
            return;
        }
        CustomerService.editCustomer(customer).then(response => {
            props.onEdited(response.data);
            setShow(false);
            setSubmitted(false);

        }).catch((err) => {
            if(err.response.data.hasOwnProperty('error_message')) {
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch)
                    showSuccess(t('success_refresh_access_token'));
                }
                if(err.response.data.error_message.includes('Email is already taken')) {
                    showError(t('error_email_already_taken'))
                }
                if(err.response.data.error_message.includes('Discount can be from 0 to 99')){
                    showError(t('error_discount_amount'))
                }
            }

            else if (err.response.data.status === 403) {
                showError(t('error_403'))

            } else {
                showError(t('unexpected_error_action'));
                console.log(err);
            }
        });
    };

    const showError = (message) => {
        setErrorMessage(message)
        setTimeout(() => {
            setErrorMessage('')
        }, 5000);
    }
    const showSuccess = (message) => {
        setSuccessMessage(message)
        setTimeout(() => {
            setSuccessMessage('')
        }, 5000);
    }

    //<input name="" onChange=(event) => handleChange(e)
    const handleChange = (e) => {
        const {name, value} = e.target;
        setCustomer((prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }));
    };
    return (
        <Modal show={show}>
            <form onSubmit={(e) => editCustomer(e)}
                  noValidate
                  className={submitted ? 'was-validated' : ''}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t('title_edit_customer')}</h5>
                    <button type="button" className="btn-close" onClick={() => setShow(false)}/>
                </div>

                <div className="modal-body">

                    {errorMessage &&
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    }
                    {successMessage &&
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    }
                    {/*Name LastName input*/}
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group"><label htmlFor="name">{t('customer_name_label')}</label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder={t('customer_name_placeholder')}
                                    className="form-control"
                                    value={customer.name}
                                    required
                                    onChange={(e) => handleChange(e)}
                                />
                                <div className="invalid-feedback">
                                    {t('name_required_form')}
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group"><label htmlFor="name">{t('lastname_label')}</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder={t('lastname_placeholder')}
                                    className="form-control"
                                    value={customer.lastName}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="form-group"><label htmlFor="name">{t('email_label')}</label>
                                <input
                                    type="text"
                                    name="email"
                                    placeholder={t('email_placeholder')}
                                    className="form-control"
                                    value={customer.email}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group"><label htmlFor="name">{t('phone_label')}</label>
                                <input
                                    type="text"
                                    name="phone"
                                    placeholder={t('phone_placeholder')}
                                    className="form-control"
                                    required
                                    value={customer.phone}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-6"><label htmlFor="discount">{t('discount_label')}</label>
                        <input
                            type="number"
                            name="discount"
                            placeholder={t('discount_placeholder')}
                            className="form-control"
                            max="99"
                            value={customer.discount}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="invalid-feedback">
                            {t('discount_amount_form')}
                        </div>
                    </div>
                    <div className="mt-3"><label htmlFor="company">{t('customer_type_label')}</label>
                        {customer.company &&
                            <select
                                name="company"
                                className="form-select"
                                onChange={(e) => handleChange(e)}
                            >
                                <option selected value="">{t('select_customer_option_value_company')}</option>
                                <option value={false}>{t('select_customer_option_value_person')}</option>
                            </select>
                        }
                        {!customer.company &&
                            <select
                                name="company"
                                className="form-select"
                                onChange={(e) => handleChange(e)}
                            >
                                <option selected value="">{t('select_customer_option_value_person')}</option>
                                <option value={true}>{t('select_customer_option_value_company')}</option>
                            </select>
                        }
                    </div>
                    <div className="form-group col-12"><label htmlFor="name">{t('address_label')}</label>
                        <input
                            type="text"
                            name="address"
                            placeholder={t('address_placeholder')}
                            className="form-control"
                            value={customer.address}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>

                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setShow(false)}>{t('button_close')}</button>
                    <button type="submit" className="btn btn-primary">{t('button_save')}</button>
                </div>
            </form>
        </Modal>
    )
});

export {CustomerEditModal};