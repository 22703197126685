// function ToolsAdminPage();
import {useEffect, useState} from "react";
import './Admin.css'
import {Link} from "react-router-dom";
import {faHandshake} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import RentalOrderService from "../../services/rental.order.service";
import {tokenExpiredHandler} from "../../store/actions/user";
import {useDispatch} from "react-redux";
import {DEFAULT_PAGE_SIZE} from "../../common/Constants";
import {Pagination} from "../../components/Pagination";


const OrdersOpenAdminPage = () => {

    const [orderList, setOrderlist] = useState([]);
    //pagination
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);


    const {t} = useTranslation();
    const dispatch = useDispatch();

    const orderStatuses = [{
        name: t('rental_order_status_open'), value: "OPEN"
    },
        {name: t('rental_order_status_in_progress'), value: "IN_PROGRESS"}, {
            name: t('rental_order_status_closed'), value: "CLOSED"
        }]

    const getOrderStatus = (rentalOrder) => {
        var status = orderStatuses.find(x => x.value === rentalOrder.rentalOrderStatus);
        return status.name;
    }

    useEffect(() => {
        RentalOrderService.getAllOpenOrders(pageNumber, pageSize).then((response) => {
            setOrderlist(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('The Token has expired')) {
                    tokenExpiredHandler(dispatch);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }, []);

    const paginate = (pageNumber) => {
        RentalOrderService.getAllOpenOrders(pageNumber, pageSize).then((response) => {
            setOrderlist(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('The Token has expired')) {
                    tokenExpiredHandler(dispatch);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }

    return (
        <div className="height-adjust container">
            <ul className="border-top col-xxl-6 col-xl-7 col-lg-8 col-sm-12 col-md-10 rounded-bottom mx-auto admin-bar nav nav-tabs justify-content-center bg-dark">
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/tools">{t('tools_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link to="/admin/categories" className="nav-link text-light"
                          aria-current="page">{t('categories_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/customers">{t('customers_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-dark fw-bold active" to="/admin/orders">{t('orders_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/users">{t('users_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/">{t('overview_tab')}</Link>
                </li>
            </ul>
            <div className="m-5">
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-6">
                                <h3><FontAwesomeIcon icon={faHandshake} className="ms-auto me-2"/>{t('table_name_open_orders')}</h3>
                            </div>
                            <div className="col-6 text-end">
                                <Link to="/admin/orders" className="mx-2 btn btn-primary">
                                    {t('button_back_to_order_menu')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="text-center table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t('header_customer')}</th>
                                <th scope="col">{t('header_phone')}</th>
                                <th scope="col">{t('header_rent_start')}</th>
                                <th scope="col">{t('header_created_by')}</th>
                                <th scope="col">{t('header_status')}</th>
                                <th scope="col">{t('header_actions')}</th>
                            </tr>
                            </thead>
                            <tbody>

                            {orderList.map((item, ind) =>
                                <tr key={item.id}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.customer.name}</td>
                                    <td>{item.customer.phone}</td>
                                    <td>{(item.rentStart).substring(0, 10) + " | " + (item.rentStart).substring(11, 16)}</td>
                                    <td>{item.createdBy}</td>
                                    <td>{getOrderStatus(item)}</td>
                                    <td>
                                        <Link
                                            to={"/admin/orders/" + item.id}
                                            type="button"
                                            className="btn btn-warning"
                                        >{t('button_go_to_order')}
                                        </Link>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {/*PAGINATION*/}
                        <Pagination pageSize={pageSize} totalItems={totalItems} paginate={paginate}/>
                        <div className='text-start'>
                            {totalPages > 1 &&
                                <div className='mx-2 d-inline-block'>{t('pagination_page')+ pageNumber}</div>
                            }
                            <div className='mx-2 d-inline-block'>{t('pagination_total')+ totalItems}</div>
                        </div>
                        {/*PAGINATION*/}
                    </div>
                </div>
            </div>
        </div>
    );

};

export {OrdersOpenAdminPage};