export default class RentalOrder {
    constructor(id, customer, rentStart, rentEnd, totalPrice, rentalOrderStatus, editable, createdAt, createdBy, lastUpdatedBy, updatedAt) {
        this.id = id;
        this.customer = customer;
        this.rentStart = rentStart;
        this.rentEnd = rentEnd;
        this.totalPrice = totalPrice;
        this.rentalOrderStatus = rentalOrderStatus;
        this.editable = editable;
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.lastUpdatedBy = lastUpdatedBy;
        this.updatedAt = updatedAt;
    }
}