import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import './CategoryModals.css'
import {Modal} from 'react-bootstrap'
import CategoryService from "../services/category.service";
import Category from "../models/category";
import {BASE_API_URL} from "../common/Constants";
import {useTranslation} from "react-i18next";
import {tokenExpiredHandler} from "../store/actions/user";
import {useDispatch} from "react-redux";

const CategoryEditModal = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        //interaction with parent
        showCategoryEditModal() {
            setTimeout(() => {
                setShow(true);
            }, 0)
        }
    }));

    const [category, setCategory] = useState(new Category('', '', ''));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(null)

    const {t} = useTranslation();
    const dispatch = useDispatch();


    //send it from parent
    useEffect(() => {
        setCategory(props.category)
        if (props.category.imagePath) {
            setImage(BASE_API_URL + props.category.imagePath);
        }
    }, [props.category])

    const editCategory = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!category.name) {
            return;
        }
        const formData = new FormData();

        formData.append("selectedFile", selectedFile);
        formData.append("category", JSON.stringify(category));

        CategoryService.editCategory(formData, category.id).then(response => {
            //...
            props.onEdited(response.data);
            setShow(false);
            setSubmitted(false);

        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('MaxUploadSizeExceededException')) {
                    setErrorMessage(t('error_image_size_too_large'));
                }
                if (err.response.data.error_message.includes('The Token has expired')) {
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
            } else if (err.response.data.status === 403) {

                setErrorMessage(t('error_403'))
            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err);
            }
        });
    };
    //<input name="" onChange=(event) => handleChange(e)
    const handleChange = (e) => {
        const {name, value} = e.target;
        setCategory((prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }));
    };
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    }
    const closeButtonAction = () => {
        setShow(false)
        if (category.imagePath) {
            setImage(BASE_API_URL + category.imagePath);
        }
    }

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
        onImageChange(event);
    }
    return (
        <Modal show={show}>
            <form onSubmit={(e) => editCategory(e)}
                  noValidate
                  className={submitted ? 'was-validated' : ''}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t('title_details_category')}</h5>
                    <button type="button" className="btn-close" onClick={() => closeButtonAction()}></button>
                </div>

                <div className="modal-body">

                    {errorMessage &&
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    }
                    {successMessage &&
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    }
                    {/*Name input*/}
                    <div className="form-group"><label htmlFor="name">{t('category_name_label')}</label>
                        <input
                            type="text"
                            name="name"
                            placeholder={t('category_name_placeholder')}
                            className="form-control"
                            required
                            value={category.name}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="invalid-feedback">
                            {t('title_required_form')}
                        </div>
                    </div>
                    <div className="form-group">
                        {category.imagePath &&
                            <img className="file-img mt-2 card-img-top" src={image}
                                 alt=""/>
                        }
                        <div className="fs-6">{t('change_image_label')}</div>
                        <input
                            type="file"
                            name="file"
                            onChange={(e) => handleFileSelect(e)}
                            className="w-50 btn btn-outline-dark form-control-file"/>
                    </div>

                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => closeButtonAction()}>
                        {t('button_close')}
                    </button>
                    <button type="submit" className="btn btn-primary">
                        {t('button_save')}
                    </button>
                </div>
            </form>
        </Modal>
    )
});

export {CategoryEditModal};