import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from 'i18next-http-backend';
import translationEN from './locales/en/translation.json'
import translationLT from './locales/lt/translation.json'

const resources = {
    en: {
        translation:translationEN
    },
    lt: {
        translation:translationLT
    }
}
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        lng: localStorage. getItem('i18nextLng') || 'lt',
        resources,
        supportedLngs: ['lt','en'],
        detection: {
            order: ['htmlTag', 'cookie', 'localStorage', 'path', 'subdomain'],
            caches: ['localStorage'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
        react: {
            useSuspense: false,
        }
    });

export default i18n;