// function ToolsAdminPage();
import {useEffect, useRef, useState} from "react";
import './Admin.css'
import ToolService from "../../services/tool.service";
import {ToolCreateModal} from "../../components/ToolCreateModal";
import Tool from "../../models/tool";
import {ToolEditModal} from "../../components/ToolEditModal";
import {Link, NavLink, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {clearCurrentUser, tokenExpiredHandler} from "../../store/actions/user";
import {clearTokens} from "../../store/actions/tokens";
import {ToolDeleteModal} from "../../components/ToolDeleteModal";
import {faTools} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {DEFAULT_PAGE_SIZE} from "../../common/Constants";
import {Pagination} from "../../components/Pagination";
import CategoryService from "../../services/category.service";


const ToolsByCategoryIdAdminPage = () => {

        let {categoryId} = useParams()

        const [toolList, setToolList] = useState([]);
        const [selectedTool, setSelectedTool] = useState(new Tool(0, '', '', 0, 0, 0, '', 0))
        const [errorMessage, setErrorMessage] = useState('');
        const [successMessage, setSuccessMessage] = useState('');
        const [categories, setCategories] = useState([]);
        //pagination
        const [totalItems, setTotalItems] = useState(0);
        const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
        const [pageNumber, setPageNumber] = useState(0);
        const [totalPages, setTotalPages] = useState(0);

        const createComponent = useRef();
        const editComponent = useRef();
        const deleteComponent = useRef();

        const {t} = useTranslation();
        const dispatch = useDispatch();

        useEffect(() => {
            ToolService.getToolsByCategoryId(categoryId, pageNumber, pageSize).then((response) => {
                setToolList(response.data.content);
                setTotalItems(response.data.totalElements);
                setPageSize(response.data.pageable.pageSize);
                setPageNumber(response.data.pageable.pageNumber);
                setTotalPages(response.data.totalPages);
            });
        }, []);

        const paginate = (pageNumber) => {
            ToolService.getToolsByCategoryId(categoryId, pageNumber, pageSize).then((response) => {
                setToolList(response.data.content);
                setTotalItems(response.data.totalElements);
                setPageSize(response.data.pageable.pageSize);
                setPageNumber(response.data.pageable.pageNumber);
                setTotalPages(response.data.totalPages);
            });
        }
        useEffect(() => {
            CategoryService.getAllCategories(pageNumber, pageSize).then((response) => {
                setCategories(response.data.content);
            })
        }, []);

        const createToolRequest = () => {
            setSelectedTool(new Tool(0, '', '', 0))
            createComponent.current?.showToolCreateModal();
        };

        const editToolRequest = (tool) => {
            setSelectedTool(tool);
            editComponent.current?.showToolEditModal();
        }
        const deleteToolRequest = (tool) => {
            setSelectedTool(tool);
            deleteComponent.current?.showToolDeleteModal();
        }

        const createToolWatcher = (tool) => {
            if (tool.category.id == categoryId) {
                const newList = toolList.concat(tool);
                setToolList(newList)
            }
        };

        //very strange, does not work without creating new list and doing concat.
        const editToolWatcher = (tool) => {
            if (tool.category.id == categoryId) {
                const index = toolList.findIndex((t) => t.id === tool.id);
                toolList[index] = tool;
                const newList = toolList.concat();
                setToolList(newList)
            } else if (tool.categoryid != categoryId) {
                setToolList(toolList.filter(x => x.id !== selectedTool.id))
            }
        };


        const deleteTool = async () => {
            ToolService.deleteTool(selectedTool).then(_ => {
                setSuccessMessage('Tool deleted Successfully');
                setErrorMessage('');
            }).catch((err) => {
                    if (err.response.data.hasOwnProperty("error_message")) {
                        if (err.response.data.error_message.includes('ConstraintViolationException')) {
                            setErrorMessage(t('error_delete_tool_actual_constrains'));
                            setSuccessMessage('');

                        }
                        if (err.response.data.error_message.includes('The Token has expired')) {
                            tokenExpiredHandler(dispatch)
                            setSuccessMessage(t('success_refresh_access_token'));
                            setErrorMessage('')
                        }

                    } else if (err.response.data.status === 403) {
                        setErrorMessage(t('error_403'))

                    } else {
                        setErrorMessage(t('unexpected_error_action'));
                        console.log(err.response.data.hasOwnProperty('error_message'));
                    }
                }
            )
            ;
        };

        return (
            <div className="height-adjust container">
                <ul className="border-top col-xxl-6 col-xl-7 col-lg-8 col-sm-12 col-md-10 rounded-bottom mx-auto admin-bar nav nav-tabs justify-content-center bg-dark">
                    <li className="nav-item">
                        <Link className="nav-link text-dark fw-bold active" to="/admin/tools">{t('tools_tab')}</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/admin/categories" className="nav-link text-light "
                              aria-current="page">{t('categories_tab')}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-light" to="/admin/customers">{t('customers_tab')}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-light" to="/admin/orders">{t('orders_tab')}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-light" to="/admin/users">{t('users_tab')}</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link text-light" to="/admin/">{t('overview_tab')}</Link>
                    </li>
                </ul>
                <div className="p-5">
                    {errorMessage &&
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    }
                    {successMessage &&
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    }
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-6">
                                    <h3><FontAwesomeIcon icon={faTools} className="ms-auto me-2"/>{t('tools_tab')}</h3>
                                </div>
                                <div className="col-6 text-end">
                                    <Link to="/admin/categories" className="mx-2 btn btn-primary">
                                        {t('button_back_to_categories')}
                                    </Link>
                                    <button className="btn btn-primary" onClick={() => createToolRequest()}>
                                        {t('button_create_tool')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <table className="table table-striped text-center">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{t('header_name')}</th>
                                    <th scope="col">{t('header_price')}</th>
                                    <th scope="col">{t('header_weight')}</th>
                                    <th scope="col">{t('header_category')}</th>
                                    <th className="text-center" scope="col">{t('header_actions')}</th>
                                </tr>
                                </thead>
                                <tbody>

                                {toolList.map((item, ind) =>
                                    <tr key={item.id}>
                                        <th scope="row">{item.id}</th>
                                        <td>{item.name}</td>
                                        <td>{`${item.price} €`}</td>
                                        {item.weight &&
                                            <td>{`${item.weight} Kg`}</td>
                                        }
                                        {!item.weight &&
                                            <td>{'-'}</td>
                                        }

                                        <td>{item.category.name}</td>

                                        <td className="w-25">
                                            <button onClick={() => editToolRequest(item)} className="btn btn-primary me-1">
                                                {t('button_edit')}
                                            </button>
                                            <button className="btn btn-danger" onClick={() => {
                                                deleteToolRequest(item)
                                            }}>
                                                {t('button_delete')}
                                            </button>
                                            <NavLink to={"/admin/tools/" + item.id + "/actual/"}
                                                     className="ms-5 btn btn-warning">
                                                {t('button_tools')}
                                            </NavLink>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                            {/*PAGINATION*/}
                            <Pagination pageSize={pageSize} totalItems={totalItems} paginate={paginate}/>
                            <div className='text-start'>
                                {totalPages > 1 &&
                                    <div className='mx-2 d-inline-block'>{t('pagination_page') + pageNumber}</div>
                                }
                                <div className='mx-2 d-inline-block'>{t('pagination_total') + totalItems}</div>
                            </div>
                            {/*PAGINATION*/}
                        </div>
                    </div>
                </div>
                <ToolCreateModal ref={createComponent} categories={categories} tool={selectedTool}
                                 onCreated={(p) => createToolWatcher(p)}/>
                <ToolEditModal ref={editComponent} categories={categories} tool={selectedTool}
                               onEdited={(p) => editToolWatcher(p)}/>
                <ToolDeleteModal ref={deleteComponent} onConfirmed={(p) => deleteTool()}/>
            </div>
        );

    }
;

export {ToolsByCategoryIdAdminPage};