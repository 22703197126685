import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Modal} from 'react-bootstrap'
import './ToolDetails.css';
import Customer from "../models/customer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate} from "react-router-dom";

const CustomerDetailsModal = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        //interaction with parent
        showCustomerDetailsModal() {
            setTimeout(() => {
                setShow(true);
            }, 0)
        }
    }));

    const [customer, setCustomer] = useState(new Customer(0,'','','',0,'',null,0,0,0,0,null));
    const [show, setShow] = useState(false);

    const {t} = useTranslation();
    const navigate = useNavigate();

    //send it from parent
    useEffect(() => {
        setCustomer(props.customer)
    }, [props.customer])

    function customeOrdersHandler() {
        navigate('/admin/orders/customer/'+customer.id);
    }

    return (
        <Modal show={show}>
            <div className="modal-header">
                <h5 className="modal-title">{t('title_details_customer')}</h5>
                <button type="button" className="btn-close" onClick={() => setShow(false)}></button>
            </div>

            <div className="modal-body">
                <div className="container row">
                    <div className="col text-center">
                        <div className="fs-4">
                            <p>{customer.name+' '+customer.lastName}</p>
                            <hr></hr>
                        </div>

                        <div className="form-group"><label htmlFor="email">{t('email_label')}</label>
                            <div className="fs-6">{customer.email}</div>
                        </div>

                        {!customer.company &&
                            <div className="form-group"><label htmlFor="company">{t('customer_type_label')}</label>
                                <div className="fs-6">{t('select_customer_option_value_person')}</div>
                            </div>
                        }
                        {customer.company &&
                            <div className="mt-2 form-group"><label htmlFor="company">Customer Type: </label>
                                <div className="fs-6">{t('select_customer_option_value_company')}</div>
                            </div>
                        }

                        {customer.discount &&
                            <div className="mt-2"><label htmlFor="discount">{t('discount_label')}</label>
                                <p>{customer.discount +' %'}</p>
                            </div>
                        }
                        {!customer.discount &&
                            <div className="mt-2"><label htmlFor="discount">{t('discount_label')}</label>
                                <p>0 %</p>
                            </div>
                        }

                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <a href={"tel:"+customer.phone} className="btn btn-success me-auto"><FontAwesomeIcon icon={faPhone}/>{' '+customer.phone}</a>
                <NavLink to={"/admin/orders/customer/"+customer.id} type="button" className="btn btn-warning">{t('button_customer_orders')}</NavLink>
                <button type="button" className="btn btn-secondary" onClick={() => setShow(false)}>{t('button_close')}</button>
            </div>
        </Modal>
    )
});

export {CustomerDetailsModal};