import {BASE_API_URL} from "../common/Constants";
import axios from "axios";
import {authHeader, refreshHeader} from "./base.service";
import {tokenExpiredHandler} from "../store/actions/user";

const BASE_URL = BASE_API_URL
class AuthenticationService{
    login(user) {
        return axios.post(BASE_URL+'/login',user);
        // return axios.post(BASE_URL+'/login',user);
    }
    register(user){
        return axios.post(BASE_URL+'/users',user);
    }
    refreshToken(){
        return axios.get(BASE_API_URL+'/token/refresh', {headers: refreshHeader()})
    }

}
export default new AuthenticationService();