import axios from "axios";
import {BASE_API_URL} from "../common/Constants";
import {authHeader, fileUploadHeader} from "./base.service";

const API_URL = BASE_API_URL+'/orderTools'

class RentalOrderToolService {

    getRentalOrderToolByOrderId(orderId) {
        return axios.get(API_URL+'/'+orderId, {headers:authHeader()});
    }
    getRentalOrderToolByToolActual(toolActualId){
        return axios.get(API_URL+'/?toolActualId='+toolActualId,{headers:authHeader()});
    }
}
export default new RentalOrderToolService();