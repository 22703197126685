import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import './CategoryModals.css'
import {Modal} from 'react-bootstrap'
import CategoryService from "../services/category.service";
import {useTranslation} from "react-i18next";
import ToolActual from "../models/toolActual";
import ToolService from "../services/tool.service";
import {useParams} from "react-router-dom";
import ToolActualService from "../services/tool.actual.service";
import {tokenExpiredHandler} from "../store/actions/user";
import {useDispatch} from "react-redux";


const ToolActualCreateModal = forwardRef((props, ref) => {
    const {t} = useTranslation();

    useImperativeHandle(ref, () => ({
        //interaction with parent
        showToolActualCreateModal() {
            setTimeout(() => {
                setShow(true);
            }, 0)
        }
    }));
    //send it from parent
    useEffect(() => {
        setToolActual(props.toolActual)
    }, [props.toolActual])


    const [toolActual, setToolActual] = useState(new ToolActual(0, '', '', 0));
    const [toolList, setToolList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);
    //result count
    const [pageSize, setPageSize] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);

    let {toolId} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        ToolService.getAllTools(pageNumber,pageSize).then((response) => {
            setToolList(response.data.content);
        });
    }, []);

    const saveToolActual = (e) => {
        e.preventDefault()
        setSubmitted(true)
        toolActual.toolId=toolId;

        if (!toolActual.serialNumber) {
            return;
        }

        console.log(toolActual);

        ToolActualService.saveTool(toolActual).then(response => {
            props.onCreated(response.data);
            setShow(false);
            setSubmitted(false);

        }).catch((err) => {
            if(err.response.data.hasOwnProperty('error_message')){
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
                if(err.response.data.error_message.includes('ConstraintViolationException')){
                    setErrorMessage(t('error_tool_actual_serial_unique'));
                }
            }
            else if (err.response.data.status === 403) {
                setErrorMessage(t('error_403'))

            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err);
            }

        });
    };

    //<input name="" onChange=(event) => handleChange(e)
    const handleChange = (e) => {
        const {name, value} = e.target;

        setToolActual((prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }));
    };

    const closeButtonAction = () => {
        setShow(false)
    }

    return (
        <Modal show={show}>

            <form onSubmit={(e) => saveToolActual(e)}
                  noValidate
                  className={submitted ? 'was-validated' : ''}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t('title_tool_actual_create')}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => closeButtonAction()}></button>
                </div>

                <div className="modal-body">

                    {errorMessage &&
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    }
                    {/*Serial Number input*/}
                    <div className="form-group"><label htmlFor="name">{t('serial_number_label')}</label>
                        <input
                            type="text"
                            name="serialNumber"
                            placeholder="Serial Number"
                            className="form-control"
                            required
                            value={toolActual.serialNumber}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="invalid-feedback">
                            {t('serial_number_required_form')}
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => closeButtonAction()}>
                        {t('button_close')}
                    </button>
                    <button type="submit" className="btn btn-primary">{t('button_save')}</button>
                </div>
            </form>
        </Modal>
    )
});

export {ToolActualCreateModal};