import axios from "axios";
import {BASE_API_URL} from "../common/Constants";
import {authHeader, fileUploadHeader} from "./base.service";

const API_URL = BASE_API_URL + '/orders'

class CustomerService {
    createRentalOrder(customer) {
        return axios.post(API_URL, customer, {headers: authHeader()});
    }

    getOrderById(orderId) {
        return axios.get(API_URL + '/' + orderId, {headers: authHeader()});
    }

    getAllOpenOrders(pageNumber, pageSize) {
        return axios.get(API_URL + '/open' + '?page=' + pageNumber + '&pageSize=' + pageSize, {headers: authHeader()});
    }

    getAllClosedOrders(pageNumber, pageSize) {
        return axios.get(API_URL + '/closed' + '?page=' + pageNumber + '&pageSize=' + pageSize, {headers: authHeader()});
    }
    getALLByCustomerId(customerId, pageNumber, pageSize) {
        return axios.get(API_URL+'/customer/'+customerId+'?page=' + pageNumber + '&pageSize=' + pageSize, {headers: authHeader()})
    }

    addItem(orderId, toolActual) {
        return axios.patch(API_URL + '/' + orderId + '/add', toolActual, {headers: authHeader()});
    }

    removeItem(orderId, toolActual) {
        return axios.patch(API_URL + '/' + orderId + '/remove', toolActual, {headers: authHeader()})
    }

    editOrder(orderId, rentalOrder) {
        return axios.put(API_URL + '/' + orderId, rentalOrder, {headers: authHeader()});
    }

    adjustDates(orderId, rentalOrder) {
        return axios.patch(API_URL + '/' + orderId + '/dates', rentalOrder, {headers: authHeader()});
    }

    statusChange(orderId, rentalOrder) {
        return axios.patch(API_URL + '/' + orderId + '/status', rentalOrder, {headers: authHeader()});
    }
    totalOverride(orderId, newTotal) {
        return axios.patch(API_URL+'/'+ orderId + '/manual'+'/'+newTotal, 0,{headers:authHeader()});
    }
}

export default new CustomerService();