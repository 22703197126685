import {useEffect, useState} from "react";
import CategoryService from "../../services/category.service";
import './CategoriesPage.css';
import {Link} from "react-router-dom";
import {BASE_API_URL, DEFAULT_PAGE_SIZE} from "../../common/Constants";
import {Pagination} from "../../components/Pagination";
import {useTranslation} from "react-i18next";

const CategoriesPage = () => {

    const [categoryList, setCategoryList] = useState([]);
    //pagination
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const {t} = useTranslation();

    useEffect(() => {
        CategoryService.getAllCategories(pageNumber, pageSize).then((response) => {
            setCategoryList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        });
    }, []);

    const paginate =(pageNumber) => {
        CategoryService.getAllCategories(pageNumber, pageSize).then((response) => {
            setCategoryList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        });
    }

    return (
        <div className="height-adjust">
            <header className="bg-dark py-1">
                <div className="container px-4 px-lg-5 my-2">
                    <div className="text-center text-white">
                            <h1 className="display-4 fw-bolder">{t('browse_categories')}</h1>
                        <p className="lead fw-normal text-white-50 mb-0">{t('select_category_header_details')}</p>
                    </div>
                </div>
            </header>
            <div className="container px-4 px-lg-5 mt-5">
                <div className="text-center row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">
                    {categoryList.map((item, ind) =>
                        <div className="col mb-3">
                            <Link to={"/categories/" + item.id} className="btn btn-outline-dark p-0 card h-100">

                                {!item.imagePath &&
                                    <img className="card-img-top w-100" src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                                         alt="..."/>
                                }
                                {item.imagePath &&
                                    <img className="card-img-top w-100" src={BASE_API_URL + item.imagePath} alt=""/>
                                }

                                <div className="card-body p-4">
                                    <div className="text-center">
                                        <h5 className="fw-bolder">{item.name}</h5>
                                        {/*{"€" + item.price}*/}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )}
                </div>
                {/*PAGINATION*/}
                <div className='paging'>
                <Pagination pageSize={pageSize} totalItems={totalItems} paginate={paginate}/>
                    {totalPages > 1 &&
                        <div className='mx-2 d-inline-block'>{'Page: '+pageNumber}</div>
                    }
                </div>
                {/*PAGINATION*/}
            </div>
        </div>
    )

}

export {CategoriesPage};