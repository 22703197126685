import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Modal} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {tokenExpiredHandler} from "../store/actions/user";
import {useDispatch} from "react-redux";
import RentalOrderService from "../services/rental.order.service";
import RentalOrder from "../models/order";

const TotalOverrideModal = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        showTotalOverrideModal() {
            setTimeout(() => {
                setShow(true);
            }, 0)
        }
    }));

    //send it from parent
    useEffect(() => {
        setRentalOrder(props.rentalOrder)
    }, [props.rentalOrder])


    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [rentalOrder, setRentalOrder] = useState(new RentalOrder(0, null, 0, 0, 0, '', null, 0, 0, 0, 0));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);
    const [newValue, setNewValue] = useState('');


    let {orderId} = useParams();

    const showError = (message) => {
        setErrorMessage(message)
        setTimeout(() => {
            setErrorMessage('')
        }, 5000);
    }

    const totalOverride = (e) => {
        e.preventDefault()
        setSubmitted(true)
        // if (!payment.amount || !payment.rentalOrderId) {
        //     return;
        // }
        RentalOrderService.totalOverride(orderId, newValue).then(response => {
            //...
            props.onOverride(response.data);
            setShow(false);
            setSubmitted(false);

        }).catch((err) => {
            if (err.response.data.hasOwnProperty('error_message')) {
                if (err.response.data.error_message.includes('The Token has expired')) {
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                } else {
                    showError(t('unexpected_error_action'));
                }
            } else {
                showError(t('unexpected_error_action'));
            }
            console.log(err);
        });
    };

    const closeButtonAction = () => {
        setShow(false)
    }

    const handleChange = (e) => {
        // const {name, value} = e.target;
        // setRentalOrder((prevState => {
        //     return {
        //         ...prevState,
        //         [name]: value
        //     };
        // }));
        setNewValue(e.target.value);
    };

    return (
        <Modal show={show}>
            <form onSubmit={(e) => totalOverride(e)}
                  noValidate
                  className={submitted ? 'was-validated' : ''}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t('title_total_override')}</h5>
                    <button type="button" className="btn-close" onClick={() => setShow(false)}/>
                </div>

                <div className="modal-body">

                    {errorMessage &&
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    }
                    {successMessage &&
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    }
                    <div className="form-group col-12"><label
                        htmlFor="amount">{t('payment_payment_amount_label')}</label>
                        <input
                            type="number"
                            name="amount"
                            placeholder={t('total_override_current_total_placeholder') + rentalOrder.totalPrice}
                            className="form-control"
                            required
                            min="1"
                            max="10000"
                            value={newValue}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>

                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary"
                            onClick={() => closeButtonAction()}>{t('button_close')}
                    </button>
                    <button type="submit" className="btn btn-primary">{t('button_confirm')}</button>
                </div>
            </form>
        </Modal>
    )
});

export {TotalOverrideModal};