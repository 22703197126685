import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import Tool from "../models/tool";
import {Modal} from 'react-bootstrap'
import {BASE_API_URL, phoneNumber} from "../common/Constants";
import {faBan, faPhone, faTools} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './ToolDetails.css';
import {useTranslation} from "react-i18next";

const ToolDetailsModal = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        //interaction with parent
        showToolDetailsModal() {
            setTimeout(() => {
                setShow(true);
            }, 0)
        }
    }));

    const [tool, setTool] = useState(new Tool());
    const [show, setShow] = useState(false);

    const {t} = useTranslation();

    //send it from parent
    useEffect(() => {
        setTool(props.tool)
    }, [props.tool])

    return (
        <Modal size="lg" show={show}>
            <div className="modal-header">
                <h5 className="modal-title">{t("table_name_tool_details")}</h5>
                <button type="button" className="btn-close" onClick={() => setShow(false)}></button>
            </div>

            <div className="modal-body">
                <div className="container row">
                    <div className="col-12 col-md-12 col-sm-12 col-lg-6 col-xl-6">
                        {!tool.imagePath &&
                            <img className="tool-image card-img-top" src="https://dummyimage.com/450x300/dee2e6/6c757d.jpg"
                                 alt="..."/>
                        }
                        {tool.imagePath &&
                            <img className="tool-image card-img-top" src={BASE_API_URL + tool.imagePath}
                                 alt=""></img>
                        }
                    </div>
                    <div className="col-12 col-md-12 col-sm-12 col-lg-6 col-xl-6">
                        {/*Name input*/}
                        <div className="text-center display-6">
                            <p>{tool.name}</p>
                            <hr></hr>
                        </div>
                        {/*Description Input*/}
                        <div className="form-group"><label htmlFor="description">{t('tool_description_label')}</label>
                            <div className="fs-6">{tool.description}</div>
                        </div>
                        {/*Price input*/}
                        <div className="mt-3 fs-4"><label htmlFor="price">{t('tool_price_label')}</label>
                            <div>{tool.price + " € / "+t('rent_day')}</div>
                        </div>
                        {/**/}
                        {tool.weight &&
                            <div className="mt-3 fs-4"><label htmlFor="weight">{t('tool_weight_label')}</label>
                                <p>{tool.weight + " Kg"}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                {tool.isAvailable &&
                    <a href={"tel:"+phoneNumber} className="btn btn-success me-auto"><FontAwesomeIcon icon={faPhone}/>{phoneNumber}</a>
                }
                {!tool.isAvailable &&
                    <button className="btn btn-danger me-auto"><FontAwesomeIcon className="me-1" icon={faBan}/>{t('button_tool_status_rented')}</button>
                }
                <button type="button" className="btn btn-secondary" onClick={() => setShow(false)}>{t('button_close')}</button>
            </div>
        </Modal>
    )
});

export {ToolDetailsModal};