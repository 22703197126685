import {NavLink, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearCurrentUser} from "../store/actions/user";
import {clearTokens} from "../store/actions/tokens";
import {GlobeIcon, languages, phoneNumber} from "../common/Constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDirections, faPhone} from "@fortawesome/free-solid-svg-icons";
import 'flag-icon-css/css/flag-icons.min.css';
import i18next from "i18next";
import {useTranslation} from "react-i18next";


const NavBar = () => {

    const {t} = useTranslation();

    const currentUser = useSelector(state => state.user);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const logout = () => {
        dispatch(clearCurrentUser())
        dispatch(clearTokens())
        navigate('/login')
    }

    return (
        <div>
            <nav className="navbar navbar navbar-expand-md navbar-dark bg-dark">
                <div className="container-fluid">
                    <NavLink to="/home" className="px-0 navbar-brand ms-5"> Eligijaus Įrankiai</NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse " id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <NavLink to="/home" className="nav-link">
                                    {t('home_page')}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/categories" className="nav-link">
                                    {t('tools_page')}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/terms" className="nav-link">
                                    {t('terms_page')}
                                </NavLink>
                            </li>
                        </ul>

                        {/*Navbar for not logged in user*/}
                        {!currentUser &&
                            <div className="navbar-nav ms-auto me-3">
                                <li className="nav-item">
                                    <a href={"tel:" + phoneNumber} className="nav-link"><FontAwesomeIcon
                                        icon={faPhone}/>{phoneNumber}</a>
                                </li>
                                <li className="nav-item">
                                    <a href="https://www.google.com/maps/place/%C4%AEranki%C5%B3+ir+%C4%AErangos+nuoma/@55.4037329,22.5706352,17z/data=!3m1!4b1!4m5!3m4!1s0x46e431ee3f386bb5:0x8c69ba46e85b1f9d!8m2!3d55.4037329!4d22.5728239"
                                       className="nav-link"><FontAwesomeIcon className="me-1"
                                                                             icon={faDirections}/>{t('directions')}</a>
                                </li>

                                {/*DUPLICATE, FIGURE HOW TO HAVE 1*/}
                                <li className="nav-item">
                                    <div className="dropdown d-flex justify-content-end">
                                        <button className="btn btn-link dropdown-toggle" type="button"
                                                id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                            <GlobeIcon/>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuButton1">
                                            {languages.map(({code, name, country_code}) => (
                                                <li key={country_code}>
                                                    <button className="dropdown-item"
                                                            onClick={() => i18next.changeLanguage(code)}>
                                                        <span className={`flag-icon flag-icon-${country_code}`}></span>
                                                        {name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </li>
                            {/* DUPLICATE FRAGMENT ABOVE.*/}

                            </div>
                        }
                        {currentUser &&
                            <div className="navbar-nav ms-auto me-3">

                                {/*Admin Panel DropDown*/}
                                {currentUser?.roleList.some(e => e.authority === "ROLE_ADMIN" || e.authority === "ROLE_USER") &&
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/admin/" id="navbarDropdown">{t('user_panel')}</NavLink>
                                    </li>
                                }

                                {/*Profile dropdown*/}
                                {/*Navbar for logged in user*/}
                                {currentUser &&
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                           role="button"
                                           data-bs-toggle="dropdown" aria-expanded="false">
                                            {currentUser.username}
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="navbarDropdown">
                                            <li className="nav-item">
                                                <NavLink to="/profile" className="dropdown-item">
                                                    {t('profile_page')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider"/>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink to="/settings" className="dropdown-item">
                                                    {t('settings_page')}
                                                </NavLink>
                                            </li>
                                            <li>
                                                <hr className="dropdown-divider"/>
                                            </li>
                                            <li className="nav-item">
                                                <a href="" className="dropdown-item"
                                                   onClick={() => logout()}>
                                                    {t('logout_action')}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                }
                                <li className="nav-item">
                                    <div className="dropdown d-flex">
                                        <button className="btn btn-link dropdown-toggle" type="button"
                                                id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                            <GlobeIcon/>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end"
                                            aria-labelledby="dropdownMenuButton1">
                                            {languages.map(({code, name, country_code}) => (
                                                <li key={country_code}>
                                                    <button className="dropdown-item"
                                                            onClick={() => i18next.changeLanguage(code)}>
                                                        <span className={`flag-icon flag-icon-${country_code}`}></span>
                                                        {name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </li>
                            </div>
                        }
                    </div>

                </div>
            </nav>
        </div>

    )
}
export {NavBar};