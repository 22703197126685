import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Modal} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import Customer from "../models/customer";
import CustomerService from "../services/customer.service";
import {useDispatch} from "react-redux";
import {tokenExpiredHandler} from "../store/actions/user";

const CustomerCreateModal = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        //interaction with parent
        showCustomerCreateModal() {
            setTimeout(() => {
                setShow(true);
            }, 0)
        }
    }));
    //send it from parent
    useEffect(() => {
        setCustomer(props.customer)
    }, [props.customer])


    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [customer, setCustomer] = useState(new Customer(0, '', '', '', 0, '', null, 0, 0, 0, 0, null));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);

    const saveCustomer = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!customer.name || !customer.phone) {
            return;
        }

        CustomerService.createCustomer(customer).then(response => {
            //...
            props.onCreated(response.data);
            setShow(false);
            setSubmitted(false);

        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('Phone number invalid')) {
                    setErrorMessage(t('error_phone_number_invalid'))
                    console.log("error message for number initated.")
                }
                if (err.response.data.error_message.includes('The Token has expired')) {
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
                if(err.response.data.error_message.includes('ConstraintViolationException')) {
                    setSuccessMessage("");
                    setErrorMessage(t('error_unique_phone'))
                }

            } else if (err.response.data.status === 403) {
                setErrorMessage(t('error_403'))

            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err);
            }
        });
    };


    //<input name="" onChange=(event) => handleChange(e)
    const handleChange = (e) => {
        const {name, value} = e.target;

        setCustomer((prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }));
    };

    const closeButtonAction = () => {
        setShow(false)
    }

    return (
        <Modal show={show}>

            <form onSubmit={(e) => saveCustomer(e)}
                  noValidate
                  className={submitted ? 'was-validated' : ''}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t('title_create_customer')}</h5>
                    <button type="button" className="btn-close" onClick={() => setShow(false)}></button>
                </div>

                <div className="modal-body">

                    {errorMessage &&
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    }
                    {successMessage &&
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    }
                    {/*Name input*/}
                    <div className="form-group"><label htmlFor="name">{t('customer_name_label')}</label>
                        <input
                            type="text"
                            name="name"
                            placeholder={t('customer_name_placeholder')}
                            className="form-control"
                            value={customer.name}
                            required
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="invalid-feedback">
                            {t('name_required_form')}
                        </div>
                    </div>
                    {/*Lastname Input*/}
                    <div className="form-group"><label htmlFor="description">{t('lastname_label')}</label>
                        <input
                            type="text"
                            name="lastName"
                            placeholder={t('lastname_placeholder')}
                            className="form-control"
                            value={customer.lastName}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    {/*Price input*/}
                    <div className="col-6">
                        <div className="form-group"><label htmlFor="name">{t('email_label')}</label>
                            <input
                                type="text"
                                name="email"
                                placeholder={t('email_placeholder')}
                                className="form-control"
                                value={customer.email}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>
                    {/**/}
                    <div className="col-6">
                        <div className="form-group"><label htmlFor="name">{t('phone_label')}</label>
                            <input
                                type="text"
                                name="phone"
                                placeholder={t('phone_placeholder')}
                                className="form-control"
                                required
                                value={customer.phone}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    </div>

                    <div className="form-group col-6"><label htmlFor="name">{t('discount_label')}</label>
                        <input
                            type="number"
                            name="discount"
                            placeholder={t('discount_placeholder')}
                            className="form-control"
                            max="99"
                            value={customer.discount}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="invalid-feedback">
                            {t('discount_amount_form')}
                        </div>
                    </div>
                    <div className="mt-3"><label htmlFor="category">{t('customer_type_label')}</label>
                        <select
                            name="company"
                            className="form-select"
                            value={customer.company}
                            onChange={(e) => handleChange(e)}
                        >
                            <option value="">{t('select_customer_type_label')}</option>
                            <option value={false}>{t('select_customer_option_value_person')}</option>
                            <option value={true}>{t('select_customer_option_value_company')}</option>

                        </select>

                    </div>
                    <div className="form-group col-12"><label htmlFor="name">{t('address_label')}</label>
                        <input
                            type="text"
                            name="address"
                            placeholder={t('address_placeholder')}
                            className="form-control"
                            value={customer.address}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>


                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => closeButtonAction()}>
                        {t('button_close')}
                    </button>
                    <button type="submit" className="btn btn-primary">
                        {t('button_save')}
                    </button>
                </div>
            </form>
        </Modal>
    )
});

export {CustomerCreateModal};