import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Modal} from "react-bootstrap";
import User from "../models/user";
import UserService from "../services/user.service";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {tokenExpiredHandler} from "../store/actions/user";

const UserDeactivateModal = forwardRef((props, ref) => {

    const [user, setUser] = useState(new User(0, '', '', 0, 0, 0, '', "", 0));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);

    const {t} = useTranslation();
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        showDisableUserModal() {
            setShow(true)
        }
    }));
    //send it from parent
    useEffect(() => {
        setUser(props.user)
    }, [props.user])


    const disableUser = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!user.username || !user.email) {
            return;
        }
        user.enabled = false;
        UserService.editUser(user).then(response => {
            //...
            props.onDisabled(response.data)
            setShow(false);
            setSubmitted(false);

        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('User must have role')) {
                    setErrorMessage(t('error_role_is_required'))
                    setSuccessMessage('');
                }
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
            }
            if (err.response.data.status === 403) {
                setErrorMessage(t('error_403'))

            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err);
            }
        });
    };

    return (
        <Modal show={show}>
            <form noValidate onSubmit={(e) => disableUser(e)}>

                <div className="modal-header">
                    <h5 className="modal-title">{t('title_user_disable')}</h5>
                    <button type="button" className="btn-close" onClick={() => setShow(false)}></button>
                </div>
                <div className="modal-body">
                    {errorMessage &&
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    }
                    {successMessage &&
                        <div className="alert alert-danger">
                            {successMessage}
                        </div>
                    }
                    {t('confirmation_disable_user_body')}
                    <hr/>
                    <p className="fw-bold">{t('deactivate_user_information')}</p>
                </div>

                <div className="modal-footer">
                    <button
                        onClick={() => setShow(false)}
                        type="button"
                        className="btn btn-secondary">{t('button_cancel')}
                    </button>
                    <button
                        type="submit"
                        className="btn btn-danger">
                        {t('button_im_sure')}
                    </button>
                </div>
            </form>
        </Modal>
    )

});

export {UserDeactivateModal};