import axios from "axios";
import {BASE_API_URL} from "../common/Constants";
import {authHeader} from "./base.service";

const API_URL = BASE_API_URL + '/users'

class UserService {
    editUser(user) {
        return axios.put(API_URL + '/' + user.id, user, {headers: authHeader()})
    }

    enableUser(user) {
        return axios.patch(API_URL + '/' + user.id, user, {headers: authHeader()})
    }

    // deleteTool(user) {
    //     return axios.delete(API_URL+'/'+tool.id,{headers:authHeader()});
    // }
    getAllUsers(pageNumber, pageSize) {
        return axios.get(API_URL + '?page=' + pageNumber + '&pageSize=' + pageSize, {headers: authHeader()});
    }

    getNotEnabledUsers(pageNumber, pageSize) {
        return axios.get(API_URL + '/new'+ '?page=' + pageNumber + '&pageSize=' + pageSize, {headers: authHeader()});
    }

    getDeactivatedUsers(pageNumber, pageSize) {
        return axios.get(API_URL + '/disabled'+ '?page=' + pageNumber + '&pageSize=' + pageSize, {headers: authHeader()})
    }

    //This is used to delete user that has just registered. Existing user may already have constraints so delete will not be used.
    deleteUser(user) {
        return axios.delete(API_URL + '/' + user.id, {headers: authHeader()})
    }
}

export default new UserService();