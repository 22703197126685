import React from 'react';

const Pagination = ({pageSize, totalItems, paginate}) => {
    const pageNumbers = [];

    for (let i = 0; i < Math.ceil(totalItems / pageSize); i++) {
        pageNumbers.push(i);
    }

    return (
        <div>
            {pageNumbers.length > 1 &&
                <ul className='pagination'>
                    {pageNumbers.map(number => (
                        <li key={number} className='page-item'>
                            <a onClick={() => paginate(number)} href='#' className='page-link active'>
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            }
        </div>
    );
};

export {Pagination};