import axios from "axios";
import {BASE_API_URL} from "../common/Constants";
import {authHeader} from "./base.service";

const API_URL = BASE_API_URL+'/tools/actual'

class ToolActualService {
    saveTool(toolActual) {
        return axios.post(API_URL, toolActual,{headers:authHeader()});
    }
    editTool(toolActual) {
        return axios.put(API_URL+'/'+toolActual.id,toolActual,{headers:authHeader()});
    }

    deleteTool(toolActualId) {
        return axios.delete(API_URL+'/'+toolActualId,{headers:authHeader()});
    }
    getAllTools() {
        return axios.get(API_URL);
    }
    getToolActualById(toolId) {
        return axios.get(API_URL+'/'+toolId);
    }
    getToolActualListByToolId(toolId, pageNumber, pageSize) {
        return axios.get(API_URL+'/tool/'+toolId+'?page='+pageNumber+'&pageSize='+pageSize);
    }

}
export default new ToolActualService();