import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import './CategoryModals.css'
import {Modal} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import ToolActual from "../models/toolActual";
import ToolActualService from "../services/tool.actual.service";
import {tokenExpiredHandler} from "../store/actions/user";
import {useDispatch} from "react-redux";

const ToolActualEditModal = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        //interaction with parent
        showToolActualEditModal() {
            setTimeout(() => {
                setShow(true);
            },)
        }
    }));

    const [toolActual, setToolActual] = useState(new ToolActual(0, '', '', 0, 0));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const toolStatuses = [{
        name: t('tool_actual_status_ready'),
        value: 'READY'
    }, {name: t('tool_actual_status_needs_review'), value: 'NEEDS_REVIEW'}, {
        name: t('tool_actual_status_in_repair'),
        value: 'IN_REPAIR'
    }];

    //send it from parent
    useEffect(() => {
        setToolActual(props.toolActual)
    }, [props.toolActual])

    const showError = (message) => {
        setErrorMessage(message)
        setTimeout(() => {
            setErrorMessage('')
        }, 5000);
    }

    const editToolActual = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!toolActual.serialNumber) {
            return;
        }

        ToolActualService.editTool(toolActual).then(response => {
            //...
            props.onEdited(response.data);
            setShow(false);
            setSubmitted(false);

        }).catch((err) => {
            if(err.response.data.hasOwnProperty('error_message')){
                console.log(err.data);
                if(err.response.data.error_message.includes('ActionNotAllowedException')){
                    showError(t('error_edit_toolActual_open_order'));
                }
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
            }
            else if (err.response) {
                if (err.response.data.status === 403) {
                    showError(t('error_403'))
                }

            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err);
            }
        });
    };
    //<input name="" onChange=(event) => handleChange(e)
    const handleChange = (e) => {
        const {name, value} = e.target;
        setToolActual((prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }));
    };
    const closeButtonAction = () => {
        setShow(false)
    }

    return (
        <Modal show={show}>
            <form onSubmit={(e) => editToolActual(e)}
                  noValidate
                  className={submitted ? 'was-validated' : ''}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t('title_tool_actual_edit')}</h5>
                    <button type="button" className="btn-close" onClick={() => closeButtonAction()}></button>
                </div>

                <div className="modal-body">

                    {errorMessage &&
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    }
                    {successMessage &&
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    }
                    {/*Name input*/}
                    <div className="form-group"><label htmlFor="name">{t('serial_number_label')}</label>
                        <input
                            type="text"
                            name="serialNumber"
                            placeholder={t('serial_number_placeholder')}
                            className="form-control"
                            required
                            value={toolActual.serialNumber}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="invalid-feedback">
                            {t('serial_number_required_form')}
                        </div>
                    </div>
                    <div className="form-group"><label htmlFor="name">{t('tool_actual_status_label')}</label>
                        <select
                            name="toolStatus"
                            className="form-select"
                            onChange={(e) => handleChange(e)}
                        >
                            <option selected value="">{t('tool_actual_status_placeholder')}</option>
                            {toolStatuses.map(({name, value}) =>
                                <option value={value}>{name}</option>
                            )}
                        </select>
                    </div>

                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => closeButtonAction()}>{t('button_close')}
                    </button>
                    <button type="submit" className="btn btn-primary">{t('button_save')}</button>
                </div>
            </form>
        </Modal>
    )
});

export {ToolActualEditModal};