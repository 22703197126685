import store from "../store";

export const authHeader = () => {
    const activeTokens = store.getState().tokens;

    return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + activeTokens?.access_token,
    }
}
export const refreshHeader = () => {
    const activeTokens = store.getState().tokens;

    return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + activeTokens?.refresh_token,
    }


}
export const fileUploadHeader = () => {
    const activeTokens = store.getState().tokens;

    return {
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer ' + activeTokens?.access_token,
    }

}

