import axios from "axios";
import {BASE_API_URL} from "../common/Constants";
import {authHeader, fileUploadHeader} from "./base.service";

const API_URL = BASE_API_URL + '/categories'

class CategoryService {
    createCategory(formData) {
        return axios.post(API_URL, formData, {headers: fileUploadHeader()})
    }

    editCategory(formData, categoryId) {
        return axios.put(API_URL + '/' + categoryId, formData, {headers: fileUploadHeader()})
    }

    deleteCategory(category) {
        return axios.delete(API_URL + '/' + category.id, {headers: authHeader()});
    }

    getAllCategories(pageNumber, pageSize) {
        return axios.get(API_URL+'?page='+pageNumber+'&pageSize='+pageSize);
    }
}

export default new CategoryService();