import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom"
import AuthenticationService from "../../services/authentication.service";
import User from "../../models/user";
import './RegisterPage.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser} from "@fortawesome/free-solid-svg-icons";

const RegisterPage = () => {
    const [user, setUser] = useState((new User('', '', '')));
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const currentUser = useSelector(state => state.user)

    const navigate = useNavigate();
    //mounted

    useEffect(() => {
        if (currentUser?.id) {
            navigate('/profile');
        }
    }, []);

    //<input onChange=(event => handleChange(event))>
    const handleChange = (e) => {
        const {name, value} = e.target;

        setUser((prevState => {
            //E.g prevState({user: abc, pass: abc}) + newKeyValue ({user:abcd}) => ({user:abcd, pass abc})
            return {
                ...prevState,
                [name]: value
            };
        }));
    };

    const handleRegister = (e) => {
        e.preventDefault()
        setSubmitted(true);

        //validation
        if (!user.username || !user.email || !user.password) {
            return
        }
        setLoading(true);
        AuthenticationService.register(user).then(_ => {
            setSuccessMessage('You have successfully registered, you can login to the system once system administrator confirms your request, you are now being redirected to Home page...')
            setErrorMessage('');
            setTimeout(() =>{
                navigate('/home');
            },4000)
        }).catch(error => {
            setErrorMessage(error?.response.data.message);
            setSuccessMessage('');
            setLoading(false);
        })
    }

    return (
        <div className="container my-5">
            {successMessage &&
                <div className="text-center alert alert-success">
                    {successMessage}
                </div>
            }
            <div className="card ms-auto me-auto p-3 shadow-lg custom-card">
                <FontAwesomeIcon icon={faUser} className="ms-auto me-auto user-icon"/>
                {errorMessage &&
                    <div className="alert alert-danger">
                        {errorMessage}
                    </div>
                }

                <form onSubmit={(e) => handleRegister(e)}
                      noValidate
                      className={submitted ? 'was-validated' : ''}
                >
                    <div className="form-group">
                        <label className="p-1 ms-1 text-start w-100" htmlFor="username">Username :</label>
                        <input type="text"
                               className="form-control"
                               required
                               name='username'
                               placeholder='Your Username'
                               value={user.username}
                               onChange={(e) => handleChange(e)}

                        />
                        <div className="invalid-feedback">
                            Invalid username
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="p-1 ms-1 text-start w-100" htmlFor="username">Email :</label>
                        <input type="text"
                               className="form-control"
                               required
                               name='email'
                               placeholder='Your Email'
                               value={user.email}
                               onChange={(e) => handleChange(e)}

                        />
                        <div className="invalid-feedback">
                            Invalid email
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="p-1 ms-1 text-start w-100" htmlFor="username">Password :</label>
                        <input type="password"
                               className="form-control"
                               required
                               name='password'
                               placeholder='Your Password'
                               value={user.password}
                               onChange={(e) => handleChange(e)}

                        />
                        <div className="invalid-feedback">
                            Invalid password
                        </div>
                    </div>
                    <button
                        className="btn btn-info w-100 mt-3"
                        disabled={loading}
                    >
                        Sign Up
                    </button>
                </form>
                <Link to="/login" className="btn btn-link" style={{color:'darkgrey'}}>
                    Already have an account?
                </Link>
            </div>
        </div>
    )

}

export {RegisterPage};