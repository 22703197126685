import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './Admin.css';
import {BASE_API_URL, IMAGES_ORDER_URL} from "../../common/Constants";


const OrdersAdminPage = () => {

    const {t} = useTranslation();


    return (
        <div className="height-adjust container">
            <ul className="border-top col-xxl-6 col-xl-7 col-lg-8 col-sm-12 col-md-10 rounded-bottom mx-auto admin-bar nav nav-tabs justify-content-center bg-dark">
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/tools">{t('tools_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link to="/admin/categories" className="nav-link text-light"
                          aria-current="page">{t('categories_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/customers">{t('customers_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-dark fw-bold active" to="/admin/orders">{t('orders_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/users">{t('users_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/">{t('overview_tab')}</Link>
                </li>
            </ul>
            <div className="my-5 mx-auto">
                <div className="card-body row text-center justify-content-center">
                    <div className="mb-5 col col-xl-4 col-lg-4 col-md-6">
                        <Link to="/admin/orders/new" className="btn btn-outline-dark order-card card w-100">
                            <div className="card-body">
                                    <h5 className="my-5 display-5 card-title">{t('button_create_new_order')}</h5>
                                </div>
                        </Link>

                    </div>
                    <div className="mb-5 col-xl-4 col-lg-4 col-md-6">
                        <Link to="/admin/orders/open" className="btn btn-outline-dark order-card card w-100">
                            <div className="card-body">
                                <h5 className="my-5 display-5 card-title">{t('button_see_open_orders')}</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="mb-5 col-xl-4 col-lg-4 col-md-6">
                        <Link to="/admin/orders/history" className="btn btn-outline-dark order-card card w-100">
                            <div className="card-body">
                                <h5 className="my-5 display-5 card-title">{t('button_see_order_history')}</h5>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    );

};
export {OrdersAdminPage}