import 'flag-icon-css/css/flag-icons.min.css';
import {useTranslation} from "react-i18next";
import './RentalOrderDocument.css'
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import RentalOrder from "../models/order";
import Customer from "../models/customer";
import {Modal} from "react-bootstrap";
import {BASE_API_URL, IMAGES_OTHER_URL, PLUS_HOURS_FROM_UTC} from "../common/Constants";

const RentalOrderDocument = forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const [rentalOrderToolList, setRentalOrderToolList] = useState([]);
    const [rentalOrder, setRentalOrder] = useState(new RentalOrder(0, new Customer(0, '', '', '', 0, '', null, 0, 0, 0, 0, null), 0, 0, 0, '', null, 0, 0, 0, 0));
    const [customer, setCustomer] = useState(new Customer(0, '', '', '', 0, '', null, 0, 0, 0, 0, null));
    const [days, setDays] = useState(0);

    useImperativeHandle(ref, () => ({
        //interaction with parent
        showRentalOrderDocument() {
            setTimeout(() => {
                setShow(true);
            }, 0)
        }
    }));

    //send it from parent
    useEffect(() => {
        setRentalOrder(props.rentalOrder);
        setCustomer(props.rentalOrder.customer);
    }, [props.rentalOrder])

    useEffect(() => {
        setRentalOrderToolList(props.rentalOrderToolList);
    },[props.rentalOrderToolList])

    useEffect(() => {
        setDays(props.days);
    },[props.days])

    function addHours(date) {
        const newDate = new Date(date);
        newDate.setHours(newDate.getHours() + PLUS_HOURS_FROM_UTC);
        let year = newDate.getFullYear();
        let month='';
        let day='';
        let hours = newDate.getHours();
        let minutes = newDate.getMinutes();
        if(newDate.getMonth() < 10) {
            month='0'+newDate.getMonth();
        }
        if(newDate.getDay() <10) {
            day = '0'+newDate.getDay();
        }
        const string = year+'-'+month+'-'+day+' | '+hours+':'+minutes;
        return string;
    }

    function closeHandler() {
        setShow(false)
    }

    return (
        <Modal onEscapeKeyDown={() => closeHandler()} fullscreen={true} scrollable={true} centered={true} show={show}>
            <div>
                <div className="printable document">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-4">
                                <img className="logo-top-left" src={BASE_API_URL + IMAGES_OTHER_URL + "logo.png"}/>
                            </div>
                            <div className="col-8 mt-4">
                                <h3>
                                    PERDAVIMO AKTAS
                                </h3>
                            </div>
                        </div>

                        <div className="row small-text">
                            <div className="text-center col-4 m-0 p-0">
                                <div className="col">
                                    <div className="row">
                                        Kliento Nr.
                                    </div>
                                    <div className="row">
                                        {rentalOrder.customer.id}
                                    </div>
                                </div>
                            </div>
                            <div className="text-center col-4 m-0 p-0">
                                <div className="col">
                                    <div className="row">
                                        Akto Nr.
                                    </div>
                                    <div className="row">
                                        {rentalOrder.id}
                                    </div>
                                </div>
                            </div>
                            <div className="text-center col-4 m-0 p-0">
                                <div className="col">
                                    <div className="row">
                                        Data
                                    </div>
                                    <div className="row">
                                        {addHours(rentalOrder.createdAt)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mt-1 mb-1 w-100"/>
                        {/*Nuomininkas*/}
                        <div className="row small-text">
                            <div className="text-center col-6 m-0 p-0">
                                <div className="col">
                                    <div className="row">
                                        Nuomininkas
                                    </div>
                                </div>
                            </div>
                            {/*Išdavimo vieta*/}
                            <div className="text-center col-6 m-0 p-0">
                                <div className="col">
                                    <div className="row">
                                        Išdavimo vieta
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Nuomininkas*/}
                        <div className="row">
                            <div className="small-text text-center col-6 m-0 p-0">
                                <div className="col">
                                    {customer.name && customer.lastName &&
                                        <div className="row">
                                            {customer.name + ', ' + customer.lastName + " ___ (Vardas,Pavardė)"}
                                        </div>
                                    }
                                    {customer.name && !customer.lastName &&
                                        <div className="row">
                                            {customer.name + ',  ___ (Vardas,Pavardė)'}
                                        </div>
                                    }
                                    {!customer.name && !customer.lastName &&
                                        <div className="row">
                                            {"____________________ (Vardas,Pavardė)"}
                                        </div>
                                    }

                                    {customer.address &&
                                        <div className="row">
                                            {customer.address + ' ___ (Adresas)'}
                                        </div>
                                    }
                                    {!customer.address &&
                                        <div className="row">
                                            {'_____________________ (Adresas)'}
                                        </div>
                                    }


                                    <div className="row">
                                        {customer.phone + ' ___ (Tel. Nr)'}
                                    </div>
                                </div>
                            </div>
                            {/*Išdavimo vieta*/}
                            <div className="font-weight-bold small-text text-center col-6 m-0 p-0">
                                <div className="col">

                                    <div className="row">
                                        SKAUDVILĖ
                                    </div>
                                    <div className="row">
                                        Sodų g. 22, LT-73438
                                    </div>
                                    <div className="row">
                                        Tauragės r.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="mt-3 mb-1 w-100"/>
                        {/*Objekto adresas*/}
                        <div className="row small-text">
                            <div className="text-center col-6 m-0 p-0">
                                <div className="col">
                                    <div className="row">
                                        Objekto adresas
                                    </div>
                                </div>
                            </div>
                            {/*Kontaktai*/}
                            <div className="text-center col-6 m-0 p-0">
                                <div className="col">
                                    <div className="row">
                                        Kontaktai
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Objekto adresas*/}
                        <div className="row">
                            <div className="text-center col-4 m-0 p-0">
                                <div className="col">
                                    <div className="row">
                                        ___________________________
                                    </div>
                                    <div className="row">
                                        ___________________________
                                    </div>
                                    <div className="row">
                                        ___________________________
                                    </div>
                                </div>
                            </div>
                            {/*Kontaktai*/}
                            <div className="col-5 m-0 p-0">
                                <div className="col small-text">
                                    <div className="row">
                                        <div className="col me-3 mt-1">Padalinio kontakt.:</div>
                                        <div className="col font-weight-bold">Eligijus Žalandauskas</div>
                                    </div>
                                    <div className="row">
                                        <div className="col me-3 mt-1">Padalinio tel.:</div>
                                        <div className="col font-weight-bold">+37067231677</div>
                                    </div>
                                    <div className="row">
                                        <div className="me-3 mt-1 col">Padalinio el. p.:</div>
                                        <div className="col font-weight-bold">eligijaus.irankiai@gmail.com</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="ms-0 small-text col-12 mt-4 table table-bordered">
                            <thead>
                            <tr className="table-row">
                                <th className="" scope="col">#</th>
                                <th className="" scope="col">Pavadinimas</th>
                                <th className="price-column" scope="col">Kaina dienai</th>
                                <th className="" scope="col">Nuo</th>
                                <th className="" scope="col">Iki</th>

                            </tr>
                            </thead>
                            <tbody>
                            {rentalOrderToolList.map((item,ind) =>
                                <tr className="table-row">
                                <th scope="row">{item.toolActual.tool.id}</th>
                                <td className="m-0 p-0 row p-0">
                                        <div className="m-0 p-0 col text-start">
                                            {item.toolActual.tool.name}
                                        </div>
                                    {item.toolActual.tool.value &&
                                        <div className="m-0 p-0 col-5 text-end">
                                            {'Įrangos vertė: '+item.toolActual.tool.value}
                                        </div>
                                    }
                                    {!item.toolActual.tool.value &&
                                        <div className="m-0 p-0 col text-end">
                                            {'Vertė:_______ Eur'}
                                        </div>
                                    }
                                </td>

                                <td>{item.toolActual.tool.price+' Eur'}</td>
                                <td>
                                    {rentalOrder.rentStart &&
                                        <p>
                                            {(rentalOrder.rentStart).toString().substring(0, 10) + " | " + (rentalOrder.rentStart).toString().substring(11, 16)}
                                        </p>
                                    }
                                </td>
                                <td>
                                    {rentalOrder.rentEnd &&
                                        <p>
                                            {(rentalOrder.rentEnd).toString().substring(0, 10) + " | " + (rentalOrder.rentEnd).toString().substring(11, 16)}
                                        </p>
                                    }
                                </td>
                                </tr>
                            )}
                            <tr className="table-row">
                                <th scope="row"></th>
                                <td className="pt-4 p-0"></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row"></th>
                                <td className="pt-4 p-0"></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="row">
                            <div className="wrapper col-6 small-text text-end">
                            </div>
                            <div className="col small-text fw-bolder text-end">
                                {'Paskaičiuota dienų: '+days}
                            </div>
                            {rentalOrder.totalPrice &&
                                <div className="col small-text fw-bolder text-end">
                                    {'Viso: '+rentalOrder.totalPrice+' Eur.'}
                                </div>
                            }
                            {!rentalOrder.totalPrice &&
                                <div className="col small-text fw-bolder text-end">
                                    {'Viso: _____ Eur.'}
                                </div>
                            }

                        </div>

                        <div>
                            Kainos nurodytos su PVM.
                        </div>

                        <div className="mt-3 salygos col-12 smaller-text">
                            <div className="font-weight-bold">
                                Asmens duomenų tvarkymas:
                            </div>
                            <div>
                                Patvirtinu, kad susipažinau su UAB "Ateivė EM" nuomos vietose ir puslapyje adresu
                                eligijausirankiai.lt
                                skelbiamais
                                Asmens duomenų tvarkymo principais ir sutinku, kad šių principų nustatyta apimtimi ir
                                tikslais UAB
                                "Ateivė EM" tvarkytų
                                mano asmens duomenis.
                                <div className="text-end">
                                    <div className="col">
                                        __________________________
                                    </div>
                                    <div className="col">(Nuomininko Parašas)</div>

                                </div>
                            </div>
                        </div>
                        <div className="salygos col-12 smaller-text">
                            <div className="font-weight-bold">
                                Nuomos sutarties sąlygos:
                            </div>
                            <div>
                                Pasirašydamas pareiškiu, kad Perdavimo akte ir Nuomos sąlygose nurodytomis sąlygomis
                                sutinku
                                sudaryti ir sudarau šiame akte nurodytos įrangos nuomos sutartį. Patvirtinu, kad su
                                nuomos sąlygomis
                                esu supažindintas prieš pasirašant Perdavimo aktą. Esu informuotas, kad Bendrosios
                                nuomos sąlygos skelbiamos ir internete adresu eligijausįrankiai.lt
                                <div className="text-end">
                                    <div className="col">
                                        __________________________
                                    </div>
                                    <div className="col">(Nuomininko Parašas)</div>

                                </div>
                            </div>
                        </div>
                        <div className="ml-3 row mt-3">
                            <div className="col-6">
                                <div className="row">
                                    Nuomotojas:
                                </div>
                                <div className="row">
                                    _________________________________
                                </div>
                                <div className="row smaller-text">
                                    (pareigos, vardas, pavardė, parašas)
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row smaller-text">
                                    Akte nurodytą įrangą (kartu su naudojimo instrukcijomis) gavau švarią, veikiančią ir
                                    akte nurodytos
                                    komplektacijos.
                                </div>
                                <div className="row">
                                    Nuomininkas:
                                </div>
                                <div className="row">
                                    _________________________________
                                </div>
                                <div className="row smaller-text">
                                    (pareigos, vardas, pavardė, parašas)
                                </div>
                            </div>
                        </div>
                        {/*Footer*/}
                        <div className="mt-2 bottom-0 row">
                            <div className="ml-4 col-4">
                                <div className="row smaller-text">
                                    UAB "Ateive EM"
                                </div>
                                <div className="row smaller-text">
                                    Kelmės g. 20, Skaudvilė, Tauragės r.
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="row smaller-text">
                                    Įmonės kodas: 300606104
                                </div>
                                <div className="row smaller-text">
                                    Tel. +37067231677
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="row smaller-text">
                                    eligijaus.irankiai@gmail.com
                                </div>
                                <div className="row smaller-text">
                                    www.eligijausirankiai.lt
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </div>
        </Modal>
    )
});

export {RentalOrderDocument};