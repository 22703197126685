import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Modal} from 'react-bootstrap'
import User from "../models/user";
import UserService from "../services/user.service";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {tokenExpiredHandler} from "../store/actions/user";

const UserEditModal = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        //interaction with parent
        showUserEditModal() {
            setTimeout(() => {
                setShow(true);
            }, 0)
        }
    }));
    const [user, setUser] = useState(new User(0, '', '', 0, 0, 0, '', '', 0));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const userRoles = [
        {name: t('user_role_user'), value: 'USER'},
        {name: t('user_role_manager'), value: 'MANAGER'},
        {name: t('user_role_accountant'), value: 'ACCOUNTANT'},
        {name: t('user_role_admin'), value: 'ADMIN'}
    ];

    //send it from parent
    useEffect(() => {
        setUser(props.user)
    }, [props.user])


    const editUser = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!user.username || !user.email) {
            setErrorMessage(t('error_user_edit_required_fields'));
            return;
        }
        UserService.editUser(user).then(response => {
            props.onEdited(response.data);
            setShow(false);
            setSubmitted(false);

        }).catch((err) => {
            if (err.response.data.hasOwnProperty('error_message')) {
                if (err.response.data.error_message.includes('The Token has expired')) {
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
            }
            if (err.response.data.status === 403) {
                setErrorMessage(t('error_403'))

            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err);
            }
        });
    };
    //<input name="" onChange=(event) => handleChange(e)
    const handleChange = (e) => {
        const {name, value} = e.target;
        setUser((prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }));
    };

    const getUserRole =(user) => {
        if(user) {
            const role = userRoles.find(({value}) => value === user.role);
            console.log(role.name);
            return role.name;
        }
    }
    return (
        <Modal show={show}>
            <form onSubmit={(e) => editUser(e)}
                  noValidate
                  className={submitted ? 'was-validated' : ''}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t('title_user_edit')}</h5>
                    <button type="button" className="btn-close" onClick={() => setShow(false)}></button>
                </div>

                <div className="modal-body">

                    {errorMessage &&
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    }
                    {successMessage &&
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    }
                    {/*Name LastName input*/}
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group"><label htmlFor="name">{t('user_name_label')}</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder={t('user_name_placeholder')}
                                    className="form-control"
                                    value={user.firstName}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group"><label htmlFor="name">{t('lastname_label')}</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder={t('lastname_placeholder')}
                                    className="form-control"
                                    value={user.lastName}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="form-group"><label htmlFor="name">{t('email_label')}</label>
                                <input
                                    type="text"
                                    name="email"
                                    placeholder={t('email_placeholder')}
                                    className="form-control"
                                    required
                                    value={user.email}
                                    onChange={(e) => handleChange(e)}
                                />
                                <div className="invalid-feedback">
                                    {t('email_required_form')}
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group"><label htmlFor="name">{t('user_username_label')}</label>
                                <input
                                    type="text"
                                    name="username"
                                    placeholder={t('user_username_placeholder')}
                                    className="form-control"
                                    required
                                    value={user.username}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group col-6"><label htmlFor="name">{t('phone_label')}</label>
                        <input
                            type="text"
                            name="phone"
                            placeholder={t('phone_placeholder')}
                            className="form-control"
                            value={user.phone}
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className="mt-3"><label htmlFor="category">{t('user_role_label')}</label>
                        <select
                            name="role"
                            className="form-select"
                            onChange={(e) => handleChange(e)}
                            value={user.role}
                        >
                            {userRoles.map(({name, value}) =>
                                <option value={value}>{name}</option>
                            )};
                            ))};
                        </select>


                        <div className="invalid-feedback">
                            {t('error_role_is_required')}
                        </div>
                    </div>


                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => setShow(false)}>{t('button_close')}</button>
                    <button type="submit" className="btn btn-primary">{t('button_save')}</button>
                </div>
            </form>
        </Modal>
    )
});

export {UserEditModal};