// function ProfilePage();
import './ProfilePage.css'
import {useTranslation} from "react-i18next"

const ProfilePage = () => {

    const{t} = useTranslation();

    return (

        <div className="height-adjust text-center">

            <p>{t('hello')}</p>
        </div>
    )

}

export {ProfilePage};