import {SET_TOKENS,CLEAR_TOKENS} from "../types";

export const setTokens = (tokens) => {
    return {
        type:SET_TOKENS,
        payload:tokens,
    };
};

export const clearTokens = () => {
    return {
        type:CLEAR_TOKENS,
    };
};