import axios from "axios";
import {BASE_API_URL} from "../common/Constants";
import {authHeader, fileUploadHeader} from "./base.service";

const API_URL = BASE_API_URL+'/customers'

class CustomerService {
    createCustomer(customer) {
        return axios.post(API_URL,customer, {headers:authHeader()})
    }
    editCustomer(customer) {
        return axios.put(API_URL+'/'+customer.id,customer,{headers:authHeader()})
    }
    deleteCustomer(customerId) {
        return axios.delete(API_URL+'/'+customerId,{headers:authHeader()});
    }
    getAllEnabledCustomers(pageNumber,pageSize) {
        return axios.get(API_URL+'/enabled'+'?page='+pageNumber+'&pageSize='+pageSize,{headers:authHeader()});
    }
    getAllDisabledCustomers(pageNumber,pageSize) {
        return axios.get(API_URL+'/disabled'+'?page='+pageNumber+'&pageSize='+pageSize,{headers:authHeader()});
    }
    findCustomerByPhone(input) {
        return axios.get(API_URL+'/find?phoneOrName='+input,{headers:authHeader()});
    }
}
export default new CustomerService();