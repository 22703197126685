// function ToolsAdminPage();
import {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import './Admin.css'
import {faUserTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserEnableModal} from "../../components/UserEnableModal";
import {useTranslation} from "react-i18next";
import Customer from "../../models/customer";
import CustomerService from "../../services/customer.service";
import {CustomerEnableModal} from "../../components/CustomerEnableModal";
import UserService from "../../services/user.service";
import {UserDeleteModal} from "../../components/UserDeleteModal";
import {CustomerDeleteModal} from "../../components/CustomerDeleteModal";
import {tokenExpiredHandler} from "../../store/actions/user";
import {useDispatch} from "react-redux";
import {DEFAULT_PAGE_SIZE} from "../../common/Constants";
import {Pagination} from "../../components/Pagination";


const CustomersDeactivatedPage = () => {

    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(new Customer(0,'','','',0,'',null,0,0,0,0,null));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    //pagination
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);


    const enableComponent = useRef();
    const deleteComponent = useRef();

    const{t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        CustomerService.getAllDisabledCustomers(pageNumber,pageSize).then((response) => {
            setCustomerList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty("error_message")) {
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }, []);

    const paginate = (pageNumber) => {
        CustomerService.getAllDisabledCustomers(pageNumber,pageSize).then((response) => {
            setCustomerList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty("error_message")) {
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }

    const enableCustomerRequest = (customer) => {
        setSelectedCustomer(customer);
        enableComponent.current?.showCustomerEnableModal();
    }

    const enableCustomerWatcher = (customer) => {
        setCustomerList(customerList.filter(x => x.id !== selectedCustomer.id))
        setSuccessMessage(t('success_customer_enable'))
    };

    const deleteCustomerRequest = (customer) => {
        setSelectedCustomer(customer);
        deleteComponent.current?.showCustomerDeleteModal();
    }
    const deleteCustomer = async () => {
        CustomerService.deleteCustomer(selectedCustomer.id).then(_ => {
            setCustomerList(customerList.filter(x => x.id !== selectedCustomer.id))
            setSuccessMessage(t('success_customer_delete'));
            setErrorMessage('');
        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('ConstraintViolationException')) {
                    setErrorMessage(t('error_delete_customer_constrains'))
                    setSuccessMessage('');
                }
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
            }
            else if (err.response.data.status === 403) {
                setErrorMessage(t('error_403'))

            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err);
            }

        });
    };

    return (
        <div className="height-adjust container">
            <ul className="border-top col-xxl-6 col-xl-7 col-lg-8 col-sm-12 col-md-10 rounded-bottom mx-auto admin-bar nav nav-tabs justify-content-center bg-dark">
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/tools">{t('tools_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link to="/admin/categories" className="nav-link text-light" aria-current="page" >{t('categories_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-dark fw-bold active" to="/admin/customers">{t('customers_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/orders">{t('orders_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/users">{t('users_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/">{t('overview_tab')}</Link>
                </li>
            </ul>
            <div className="p-5">
                {errorMessage &&
                    <div className="alert alert-danger">
                        {errorMessage}
                    </div>
                }
                {successMessage &&
                    <div className="alert alert-success">
                        {successMessage}
                    </div>
                }
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-6">
                                <h3><FontAwesomeIcon icon={faUserTimes} className="ms-auto me-2 category-icon"/>{t('table_name_deactivated_customers')}</h3>
                            </div>
                            <div className="col-6 text-end">
                                <Link to="/admin/customers" className="mx-2 btn btn-primary">
                                    {t('button_back_to_customers')}
                                </Link>
                            </div>
                            <div className="col-6 text-end">
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table table-striped text-center">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t('header_name')}</th>
                                <th scope="col">{t('header_lastname')}</th>
                                <th scope="col">{t('header_email')}</th>
                                <th scope="col">{t('header_phone')}</th>
                                <th scope="col">{t('header_created_date')}</th>
                                <th scope="col">{t('header_deactivated_date')}</th>
                                <th className="text-center" scope="col">{t('header_actions')}</th>
                            </tr>
                            </thead>
                            <tbody>

                            {customerList.map((item, ind) =>
                                <tr key={item.id}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.name}</td>
                                    <td>{item.lastName}</td>
                                    <td>{item.email}</td>
                                    <td>{item.phone}</td>
                                    <td>{item.createdAt.substring(0, 10) + " | " + item.createdAt.substring(11, 16)}</td>
                                    {item.lastUpdatedAt &&
                                        <td>{item.lastUpdatedAt.substring(0, 10) + " | " + item.lastUpdatedAt.substring(11, 16)}</td>
                                    }
                                    <td className="w-25">
                                        <button className="btn btn-primary me-1"
                                                onClick={() => enableCustomerRequest(item)}>
                                            {t('button_enable')}
                                        </button>
                                        <button
                                            onClick={() => deleteCustomerRequest(item)}
                                            className="btn btn-danger">
                                            {t('button_delete')}
                                        </button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {/*PAGINATION*/}
                        <Pagination pageSize={pageSize} totalItems={totalItems} paginate={paginate}/>
                        <div className='text-start'>
                            {totalPages > 1 &&
                                <div className='mx-2 d-inline-block'>{t('pagination_page')+pageNumber}</div>
                            }
                            <div className='mx-2 d-inline-block'>{t('pagination_total')+totalItems}</div>
                        </div>
                        {/*PAGINATION*/}
                    </div>
                </div>
            </div>
            <CustomerEnableModal ref={enableComponent} customer={selectedCustomer} onEnabled={(p) => enableCustomerWatcher(p)}/>
            <CustomerDeleteModal ref={deleteComponent} onConfirmed={(p) => deleteCustomer()}/>

        </div>
    );

};

export {CustomersDeactivatedPage};