import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHandshake} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import RentalOrder from "../../models/order";
import Customer from "../../models/customer";
import CustomerService from "../../services/customer.service";
import RentalOrderService from "../../services/rental.order.service";
import React, { Component } from 'react'
import Select from 'react-select'
import {DEFAULT_PAGE_SIZE} from "../../common/Constants";
import {tokenExpiredHandler} from "../../store/actions/user";
import {useDispatch} from "react-redux";

const NewOrderPage = () => {
    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(new Customer(0, '', '', '', 0, '', null, 0, 0, 0, 0, null));
    const [rentalOrder, setRentalOrder] = useState(new RentalOrder(0, null, 0, 0, 0, '', null, 0, 0, 0, 0));
    const [errorMessage, setErrorMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    //pagination
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const {t} = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch();

    useEffect(() => {
        CustomerService.getAllEnabledCustomers(pageNumber,pageSize).then((response) => {
            setCustomerList(response.data.content);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty("error_message")) {
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        console.log(selectedCustomer);
        setSelectedCustomer(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        });
    };

    const createNewOrder = (e) => {
        e.preventDefault()
        setSubmitted(true)
        if (!selectedCustomer.id) {
            console.log(selectedCustomer);
            return;
        }
        RentalOrderService.createRentalOrder(selectedCustomer).then(response => {
            navigate('/admin/orders/'+response.data.id)
            //...
            // props.onCreated(response.data);
            // setShow(false);
            //  setSubmitted(false);


        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if(err.response.data.error_message.includes('EntityNotFoundException')) {
                    setErrorMessage('Customer not found in the system');
                }
                setErrorMessage(err.response.data.error_message);
            } else if (err.response.data.status === 403) {
                setErrorMessage(t('error_403'))

            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err);
            }
        });
    };
    const findByPhone =(input) => {
        if(input.length >= 4 && input.length<8) {
            CustomerService.findCustomerByPhone(input).then(response => {
                setCustomerList(response.data);
                console.log(customerList);
            });
        }
    }

    return (
        <form
            onSubmit={(e) => createNewOrder(e)}
            noValidate
            className={submitted ? 'was-validated' : ''}
        >
            <div className="height-adjust">
                <div className="my-5 container col-xl-8 col-lg-6 col-md-6">
                    <div className="">
                        {errorMessage &&
                            <div className="alert alert-danger">
                                {errorMessage}
                            </div>
                        }
                        {successMessage &&
                            <div className="alert alert-success">
                                {successMessage}
                            </div>
                        }
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col">
                                        <h3><FontAwesomeIcon icon={faHandshake} className="ms-auto me-2"/>{t('table_name_new_order')}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="text-start">
                                    <div className="m-3"><label htmlFor="category">{t('select_customer_label')}</label>
                                        <Select
                                            className="customer-select"
                                            placeholder={t('select_customer_placeholder')}
                                            options={customerList}
                                            isSearchable={true}
                                            getOptionLabel={(option) => `${option.name}, ${option.lastName}, ${option.phone}`}
                                            onInputChange={(input)=>findByPhone(input)}
                                            onChange={setSelectedCustomer}
                                        />
                                        <div className="invalid-feedback">
                                            {t('error_create_order_customer_required')}
                                        </div>
                                    </div>
                                    <button type="submit" className="w-100 btn btn-primary">{t('button_start')}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    )
}
export {NewOrderPage}