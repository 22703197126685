import {forwardRef, useImperativeHandle, useState} from "react";
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const UserDeleteModal = forwardRef((props, ref) => {

    const [show, setShow] = useState(false);

    const {t} = useTranslation();

    useImperativeHandle(ref, () =>({
        showUserDeleteModal() {
            setShow(true)
        }
    }));
    const deleteUser = () => {
        props.onConfirmed();
        setShow(false);
    }

    return (
        <Modal show={show}>
            <div className="modal-header">
                <h5 className="modal-title">{t('title_user_delete')}</h5>
                <button type="button" className="btn-close" onClick={()=>setShow(false)}></button>
            </div>
            <div className="modal-body">
                {t('confirmation_delete_user_body')}
            </div>
            <div className="modal-footer">
                <button
                    onClick={()=>setShow(false)}
                    type="button"
                    className="btn btn-secondary">{t('button_cancel')}</button>
                <button
                    onClick={()=>deleteUser()}
                    type="button"
                    className="btn btn-danger">{t('button_im_sure')}</button>
            </div>
        </Modal>
    )

});

export {UserDeleteModal};