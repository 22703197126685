// function UnauthorizedPage();

import {Link} from "react-router-dom";
import './Error.css'
import {useTranslation} from "react-i18next";

const UnauthorizedPage = () => {

    const{t} = useTranslation();

    return (
        <div className="height-adjust container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <span className="display-1">
                        401
                    </span>
                    <div className="mb-4 lead">
                        {t('error_401')}
                    </div>
                    <Link to="/home" className = "btn btn-link">
                        {t('error_back_home')}
                    </Link>
                </div>
            </div>
        </div>    )

}

export {UnauthorizedPage};