// function ToolsAdminPage();
import {useEffect, useRef, useState} from "react";
import './Admin.css'
import ToolService from "../../services/tool.service";
import Tool from "../../models/tool";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {clearCurrentUser, tokenExpiredHandler} from "../../store/actions/user";
import {clearTokens} from "../../store/actions/tokens";
import User from "../../models/user";
import UserService from "../../services/user.service";
import {UserEditModal} from "../../components/UserEditModal";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {UserEnableModal} from "../../components/UserEnableModal";
import {UserDeactivateModal} from "../../components/UserDeactivateModal";
import {DEFAULT_PAGE_SIZE} from "../../common/Constants";
import {Pagination} from "../../components/Pagination";


const UserAdminPage = () => {

    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(new User(0, '', '', 0, 0, 0, '', "", 0));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    //pagination
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const editComponent = useRef();
    const disableComponent = useRef();

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const userRoles = [{name: t('user_role_user'), value: 'USER'}, {name: t('user_role_manager'), value: 'MANAGER'
    }, {name: t('user_role_accountant'), value: 'ACCOUNTANT'}, {name: t('user_role_admin'), value: 'ADMIN'}]

    useEffect(() => {
        UserService.getAllUsers(pageNumber, pageSize).then((response) => {
            setUserList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty("error_message")) {
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }, []);
    const paginate = (pageNumber) => {
        UserService.getAllUsers(pageNumber, pageSize).then((response) => {
            setUserList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty("error_message")) {
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }

    const editUserRequest = (user) => {
        setSelectedUser(user);
        editComponent.current?.showUserEditModal();
    }
    const userDeactivateRequest = (user) => {
        setSelectedUser(user);
        disableComponent.current?.showDisableUserModal();
    }


    //very strange, does not work without creating new list and doing concat.
    const editUserWatcher = (user) => {
        const index = userList.findIndex((t) => t.id === user.id);
        userList[index] = user;
        const newList = userList.concat();
        setUserList(newList)
    };
    const userDeactivateWatcher = (user) => {
        setUserList(userList.filter(x => x.id !== selectedUser.id))
    }
    const getUserRole = (user) => {
        var status = userRoles.find(x => x.value === user.role);
        return status.name;
    }

    return (
        <div className="height-adjust container">
            <ul className="border-top col-xxl-6 col-xl-7 col-lg-8 col-sm-12 col-md-10 rounded-bottom mx-auto admin-bar nav nav-tabs justify-content-center bg-dark">
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/tools">{t('tools_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link to="/admin/categories" className="nav-link text-light"
                          aria-current="page">{t('categories_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/customers">{t('customers_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/orders">{t('orders_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-dark fw-bold active" to="/admin/users">{t('users_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/">{t('overview_tab')}</Link>
                </li>
            </ul>
            <div className="p-5">
                {errorMessage &&
                    <div className="alert alert-danger">
                        {errorMessage}
                    </div>
                }
                {successMessage &&
                    <div className="alert alert-success">
                        {successMessage}
                    </div>
                }
                <div className="card">
                    <div className="m-2">
                        <div className="row">
                            <div className="col-6">
                                <h3><FontAwesomeIcon icon={faUsers} className="ms-auto me-2"/>{t('table_name_users')}</h3>
                            </div>
                            <div className="col-6 text-end">
                                <Link to="/admin/users/disabled" className="mx-2 btn btn-primary">
                                    {t('button_deactivated_users')}
                                </Link>
                                <Link to="/registration/requests" className="mx-2 btn btn-primary">
                                    {t('button_registration_requests')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <hr className="m-0"/>
                    <div className="card-body">
                        <table className="table table-striped text-center">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t('header_username')}</th>
                                <th scope="col">{t('header_email')}</th>
                                <th scope="col">{t('header_firstname')}</th>
                                <th scope="col">{t('header_lastname')}</th>
                                <th scope="col">{t('header_phone')}</th>
                                <th scope="col">{t('header_user_role')}</th>
                                <th scope="col">{t('header_created_date')}</th>
                                <th className="text-center" scope="col">{t('header_actions')}</th>
                            </tr>
                            </thead>
                            <tbody>

                            {userList.map((item, ind) =>
                                <tr key={item.id}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.username}</td>
                                    <td>{item.email}</td>
                                    <td>{item.firstName}</td>
                                    <td>{item.lastName}</td>
                                    <td>{item.phone}</td>
                                    <td>{getUserRole(item)}</td>
                                    <td>{(item.createdAt).substring(0, 10) + " | " +(item.createdAt).substring(11, 16)}</td>


                                    <td className="w-25">
                                        <button onClick={() => editUserRequest(item)} className="btn btn-primary me-1">
                                            {t('button_edit')}
                                        </button>
                                        <button className="btn btn-danger" onClick={() => {
                                            userDeactivateRequest(item);
                                        }}>
                                            {t('button_deactivate')}
                                        </button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {/*PAGINATION*/}
                        <Pagination pageSize={pageSize} totalItems={totalItems} paginate={paginate}/>
                        <div className='text-start'>
                            {totalPages > 1 &&
                                <div className='mx-2 d-inline-block'>{t('pagination_page')+pageNumber}</div>
                            }
                            <div className='mx-2 d-inline-block'>{t('pagination_total')+totalItems}</div>
                        </div>
                        {/*PAGINATION*/}
                    </div>
                </div>
            </div>
            <UserEditModal ref={editComponent} user={selectedUser} onEdited={(p) => editUserWatcher(p)}/>
            <UserDeactivateModal ref={disableComponent} user={selectedUser}
                                 onDisabled={(p) => userDeactivateWatcher(p)}/>
        </div>
    );

};

export {UserAdminPage};