// function LoginPage();

import {useEffect, useState} from "react";
import User from "../../models/user";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import AuthenticationService from "../../services/authentication.service";
import {setCurrentUser} from "../../store/actions/user";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import '../register/RegisterPage.css';
import {setTokens} from "../../store/actions/tokens";

const LoginPage = () => {

    const [user, setUser] = useState((new User()));
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const currentUser = useSelector(state => state.user)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    //mounted

    useEffect(() => {
        if (currentUser?.id) {
            navigate('/admin/orders');
        }
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;

        setUser((prevState => {
            //E.g prevState({user: abc, pass: abc}) + newKeyValue ({user:abcd}) => ({user:abcd, pass abc})
            return {
                ...prevState,
                [name]: value
            }
        }))
    };

    const handleLogin = (e) => {
        e.preventDefault();

        setSubmitted(true);
        if(!user.username || !user.password) {
            return;
        }
        setLoading(true);
        AuthenticationService.login(user).then(response => {
            //set user in session.
            dispatch(setCurrentUser(response.data.userLoginResponseBody));
            dispatch(setTokens(response.data.tokens))
            navigate('/admin/orders');
        }).catch(error => {
            console.log(error);
            setErrorMessage('Failed to Login')
            setLoading(false);
        })
    }

    return (
        <div className="container my-5">
            <div className="card ms-auto me-auto p-3 shadow-lg custom-card">
                <FontAwesomeIcon icon={faUser} className="ms-auto me-auto user-icon"/>
                {errorMessage &&
                    <div className="alert alert-danger">
                        {errorMessage}
                    </div>
                }
                <form onSubmit={(e) => handleLogin(e)}
                      noValidate
                      className={submitted ? 'was-validated' : ''}
                >
                    <div className="form-group">
                        <label className="p-1 ms-1 text-start w-100" htmlFor="username">Username :</label>
                        <input type="text"
                               className="form-control"
                               required
                               name='username'
                               placeholder='Your Username'
                               value={user.username}
                               onChange={(e) => handleChange(e)}

                        />
                        <div className="invalid-feedback">
                            Invalid username
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="p-1 ms-1 text-start w-100" htmlFor="username">Password :</label>
                        <input type="password"
                               className="form-control"
                               required
                               name='password'
                               placeholder='Your Password'
                               value={user.password}
                               onChange={(e) => handleChange(e)}

                        />
                        <div className="invalid-feedback">
                            Invalid password
                        </div>
                    </div>
                    <button
                        className="btn btn-info w-100 mt-3"
                        disabled={loading}
                    >
                        Sign in
                    </button>
                </form>
                <Link to="/register" className="btn btn-link" style={{color:'darkgrey'}}>
                    Dont have an account?
                </Link>
            </div>
        </div>


    )

}

export {LoginPage};