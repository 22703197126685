import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {NavBar} from './components/NavigationBar'
import {HomePage} from './pages/home/HomePage';
import {LoginPage} from "./pages/login/LoginPage";
import {RegisterPage} from "./pages/register/RegisterPage";
import {ProfilePage} from "./pages/profile/ProfilePage";
import {ToolsAdminPage} from "./pages/admin/ToolsAdminPage";
import {NotFound} from "./pages/error/NotFound";
import {UnauthorizedPage} from "./pages/error/UnauthorizedPage";
import {AuthGuard} from "./guards/AuthGuard";
import {ToolActualPage} from "./pages/toolsActual/ToolActualPage";
import {AdminPage} from "./pages/admin/AdminPage";
import {CategoriesAdminPage} from "./pages/admin/CategoriesAdminPage";
import {CategoriesPage} from "./pages/categories/CategoriesPage";
import {ToolByCategoryIdPage} from "./pages/tools/ToolByCategoryIdPage";
import {UserAdminPage} from "./pages/admin/UserAdminPage";
import {UserEnableAdminPage} from "./pages/admin/UserEnableAdminPage";
import {Footer} from "./components/Footer";
import {ToolActualByToolIdAdminPage} from "./pages/admin/ToolActualByToolIdAdminPage";
import {UserDeactivatedPage} from "./pages/admin/UserDeactivatedPage";
import {ToolsByCategoryIdAdminPage} from "./pages/admin/ToolsByCategoryIdAdminPage";
import {CustomersAdminPage} from "./pages/admin/CustomersAdminPage";
import {CustomersDeactivatedPage} from "./pages/admin/CustomersDeactivatedPage";
import {OrdersAdminPage} from "./pages/admin/OrdersAdminPage";
import {NewOrderPage} from "./pages/order/NewOrderPage";
import {OrderByIdPage} from "./pages/order/OrderByIdPage";
import {OrdersOpenAdminPage} from "./pages/admin/OrdersOpenAdminPage";
import {OrdersClosedAdminPage} from "./pages/admin/OrdersClosedAdminPage";
import {CustomerOrdersPage} from "./pages/order/CustomerOrdersPage";
import {Terms} from "./pages/terms/terms";
import {SettingsPage} from "./pages/settings/SettingsPage";

function App() {
    return (
        <BrowserRouter>
            <NavBar/>
            <Routes>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/register" element={<RegisterPage/>}/>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/home" element={<HomePage/>}/>
                <Route path="/categories" element={<CategoriesPage/>}/>
                <Route path="/categories/:categoryId" element={<ToolByCategoryIdPage/>}/>
                <Route path="/terms" element={<Terms/>}/>


                <Route path="/tools/actual" element={
                    <AuthGuard roles={["ROLE_USER", "ROLE_ADMIN"]}>
                        <ToolActualPage/>
                    </AuthGuard>
                }/>


                <Route path="/admin/tools/:toolId/actual/" element={
                    <AuthGuard roles={["ROLE_USER", "ROLE_ADMIN"]}>
                    <ToolActualByToolIdAdminPage/>
                    </AuthGuard>
                        }/>

                <Route path="/admin/categories/:categoryId/tools/" element={
                    <AuthGuard roles={["ROLE_USER", "ROLE_ADMIN"]}>
                        <ToolsByCategoryIdAdminPage/>
                    </AuthGuard>
                }/>

                <Route path="/profile" element={
                    <AuthGuard roles={["ROLE_USER", "ROLE_ADMIN"]}>
                        <ProfilePage/>
                    </AuthGuard>
                }/>
                {/*General Admin page*/}
                <Route path="/admin" element={
                    <AuthGuard roles={["ROLE_ADMIN", "ROLE_USER"]}>
                        <AdminPage/>
                    </AuthGuard>
                }/>
                {/*Admin Tools Page*/}
                <Route path="/admin/tools" element={
                    <AuthGuard roles={["ROLE_ADMIN", "ROLE_USER"]}>
                        <ToolsAdminPage/>
                    </AuthGuard>
                }/>
                <Route path="/admin/categories" element={
                    <AuthGuard roles={["ROLE_ADMIN", "ROLE_USER"]}>
                        <CategoriesAdminPage/>
                    </AuthGuard>
                }/>
                <Route path="/admin/users" element={
                    <AuthGuard roles={["ROLE_ADMIN", "ROLE_USER"]}>
                        <UserAdminPage/>
                    </AuthGuard>
                }/>
                <Route path="/registration/requests" element={
                    <AuthGuard roles={["ROLE_ADMIN", "ROLE_USER"]}>
                        <UserEnableAdminPage/>
                    </AuthGuard>
                }/>
                <Route path="/admin/users/disabled" element={
                    <AuthGuard roles={["ROLE_ADMIN"]}>
                        <UserDeactivatedPage/>
                    </AuthGuard>
                }/>
                <Route path="/admin/customers" element={
                    <AuthGuard roles={["ROLE_ADMIN", "ROLE_USER"]}>
                        <CustomersAdminPage/>
                    </AuthGuard>
                }/>
                <Route path="/admin/customers/disabled" element={
                    <AuthGuard roles={["ROLE_ADMIN"]}>
                        <CustomersDeactivatedPage/>
                    </AuthGuard>
                }/>
                <Route path="/admin/orders" element={
                    <AuthGuard roles={["ROLE_ADMIN","ROLE_USER"]}>
                        <OrdersAdminPage/>
                    </AuthGuard>
                }/>
                <Route path="/admin/orders/new" element={
                    <AuthGuard roles={["ROLE_ADMIN","ROLE_USER"]}>
                        <NewOrderPage/>
                    </AuthGuard>
                }/>
                <Route exact path="/admin/orders/:orderId" element={
                    <AuthGuard roles={["ROLE_ADMIN","ROLE_USER"]}>
                        <OrderByIdPage/>
                    </AuthGuard>
                }/>

                <Route exact path="/admin/orders/open" element={
                    <AuthGuard roles={["ROLE_ADMIN","ROLE_USER"]}>
                        <OrdersOpenAdminPage/>
                    </AuthGuard>
                }/>

                <Route exact path="/admin/orders/history" element={
                    <AuthGuard roles={["ROLE_ADMIN","ROLE_USER"]}>
                        <OrdersClosedAdminPage/>
                    </AuthGuard>
                }/>
                <Route exact path="/admin/orders/customer/:customerId" element={
                    <AuthGuard roles={["ROLE_ADMIN","ROLE_USER"]}>
                        <CustomerOrdersPage/>
                    </AuthGuard>
                }/>

                {/*Settings Page*/}
                <Route path="/settings" element={
                    <AuthGuard roles={["ROLE_ADMIN", "ROLE_USER"]}>
                        <SettingsPage/>
                    </AuthGuard>
                }/>


                <Route path="/404" element={<NotFound/>}/>
                <Route path="/401" element={<UnauthorizedPage/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}



export default App;
