// function ToolsAdminPage();
import {useEffect, useRef, useState} from "react";
import './Admin.css'
import {Link, NavLink, useNavigate} from "react-router-dom";
import {faUserFriends} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import Customer from "../../models/customer";
import CustomerService from "../../services/customer.service";
import {CustomerDeactivateModal} from "../../components/CustomerDeactivateModal";
import {CustomerEditModal} from "../../components/CustomerEditModal";
import {CustomerCreateModal} from "../../components/CustomerCreateModal";
import {tokenExpiredHandler} from "../../store/actions/user";
import {useDispatch} from "react-redux";
import {Pagination} from "../../components/Pagination";
import {DEFAULT_PAGE_SIZE} from "../../common/Constants";


const CustomersAdminPage = () => {

    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(new Customer(0,'','','',0,'',null,0,0,0,0,null));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    //pagination
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const editComponent = useRef();
    const disableComponent = useRef();
    const createComponent = useRef();

    const{t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        CustomerService.getAllEnabledCustomers(pageNumber,pageSize).then((response) => {
            setCustomerList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty("error_message")) {
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }, []);

    const paginate = (pageNumber) => {
        CustomerService.getAllEnabledCustomers(pageNumber,pageSize).then((response) => {
            setCustomerList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty("error_message")) {
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }

    const createCustomerRequest = (customer) => {
        setSelectedCustomer(new Customer(0,'','','',0,'',null,0,0,0,0,null));
        createComponent.current?.showCustomerCreateModal();
    }

    const editCustomerRequest = (customer) => {
        setSelectedCustomer(customer);
        editComponent.current?.showCustomerEditModal();
    }

    const customerDeactivateRequest = (customer) => {
        setSelectedCustomer(customer);
        disableComponent.current?.showDisableCustomerModal();
    }


    //very strange, does not work without creating new list and doing concat.
    const   editCustomerWatcher = (customer) => {
        const index = customerList.findIndex((t) => t.id === customer.id);
        customerList[index] = customer;
        const newList = customerList.concat();
        setCustomerList(newList)
    };
    const customerDeactivateWatcher = (customer) => {
        setCustomerList(customerList.filter(x => x.id !== selectedCustomer.id))
    }
    const createCustomerWatcher = (customer) => {
        const newList = customerList.concat(customer);
        setCustomerList(newList)
    };

    function findCustomer(input) {
        if(input.target.value.length >= 4 && input.target.value.length<8) {
            CustomerService.findCustomerByPhone(input.target.value).then(response => {
                setCustomerList(response.data);
                setTotalPages(0);
                setTotalItems(response.data.length)
            });
        }
        if(input.target.value ==="") {
            CustomerService.getAllEnabledCustomers(pageNumber,pageSize).then((response) => {
                setCustomerList(response.data.content);
                setTotalItems(response.data.totalElements);
                setPageSize(response.data.pageable.pageSize);
                setPageNumber(response.data.pageable.pageNumber);
                setTotalPages(response.data.totalPages);
            }).catch((err) => {
                if(err.response.data.hasOwnProperty("error_message")) {
                    if(err.response.data.error_message.includes('The Token has expired')){
                        tokenExpiredHandler(dispatch);
                        setSuccessMessage(t('success_refresh_access_token_reload'));
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }
                }
            });
        }
    }

    return (
        <div className="height-adjust container">
            <ul className="border-top col-xxl-6 col-xl-7 col-lg-8 col-sm-12 col-md-10 rounded-bottom mx-auto admin-bar nav nav-tabs justify-content-center bg-dark">
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/tools">{t('tools_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link to="/admin/categories" className="nav-link text-light" aria-current="page" >{t('categories_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-dark fw-bold active" to="/admin/customers">{t('customers_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/orders">{t('orders_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/users">{t('users_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/">{t('overview_tab')}</Link>
                </li>
            </ul>
            <div className="p-5">
                {errorMessage &&
                    <div className="alert alert-danger">
                        {errorMessage}
                    </div>
                }
                {successMessage &&
                    <div className="alert alert-success">
                        {successMessage}
                    </div>
                }
                <div className="card">
                    <div className="m-2">
                        <div className="row">
                            <div className="col-6">
                                <h3><FontAwesomeIcon icon={faUserFriends} className="ms-auto me-2"/>{t('table_name_customers')}</h3>
                            </div>
                            <div className="col-xxl-6 text-end">
                                <input
                                    className="small-text"
                                    placeholder={t('find_customer_by_name_phone')}
                                    onChange={e => findCustomer(e) }
                                />
                                <Link to="/admin/customers/disabled" className="mx-2 btn btn-primary">
                                    {t('button_deactivated_customers')}
                                </Link>
                                <button onClick={()=>createCustomerRequest()} className="mx-2 btn btn-primary">
                                    {t('button_create_customer')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr className="m-0"/>
                    <div className="card-body text-center">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t('header_firstname')}</th>
                                <th scope="col">{t('header_lastname')}</th>
                                <th scope="col">{t('header_email')}</th>
                                <th scope="col">{t('header_phone')}</th>
                                <th scope="col">{t('header_created_date')}</th>
                                <th className="text-center" scope="col">{t('header_actions')}</th>
                            </tr>
                            </thead>
                            <tbody>

                            {customerList.map((item, ind) =>
                                <tr key={item.id}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.name}</td>
                                    <td>{item.lastName}</td>
                                    <td>{item.email}</td>
                                    <td><a href={"tel:"+item.phone}>{item.phone}</a></td>
                                    <td>{item.createdAt.substring(0, 10) + " | " + item.createdAt.substring(11, 16)}</td>


                                    <td className="w-25">
                                        <button onClick={() => editCustomerRequest(item)} className="btn btn-primary me-1">
                                            {t('button_edit')}
                                        </button>
                                        <button className="btn btn-danger me-1" onClick={() =>
                                            customerDeactivateRequest(item)
                                        }>
                                            {t('button_deactivate')}
                                        </button>
                                        <NavLink to={"/admin/orders/customer/"+item.id} type="button" className="btn btn-warning">{t('button_orders')}</NavLink>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {/*PAGINATION*/}
                        <Pagination pageSize={pageSize} totalItems={totalItems} paginate={paginate}/>
                        <div className='text-start'>
                            {totalPages > 1 &&
                                <div className='mx-2 d-inline-block'>{t('pagination_page')+pageNumber}</div>
                            }
                            <div className='mx-2 d-inline-block'>{t('pagination_total')+totalItems}</div>
                        </div>
                        {/*PAGINATION*/}
                    </div>
                </div>
            </div>
            <CustomerCreateModal ref={createComponent} customer={selectedCustomer} onCreated={(p) => createCustomerWatcher(p)}/>
            <CustomerEditModal ref={editComponent} customer={selectedCustomer} onEdited={(p) => editCustomerWatcher(p)}/>
            <CustomerDeactivateModal ref={disableComponent} customer={selectedCustomer} onDisabled={(p) => customerDeactivateWatcher(p)}/>
        </div>
    );

};

export {CustomersAdminPage};