import {forwardRef, useImperativeHandle, useState} from "react";
import {Modal} from "react-bootstrap";

const ToolDeleteModal = forwardRef((props, ref) => {

    const [show, setShow] = useState(false);

    useImperativeHandle(ref, () =>({
        showToolDeleteModal() {
            setShow(true)
        }
    }));
    const deleteTool = () => {
        props.onConfirmed();
        setShow(false);
    }

    return (
        <Modal show={show}>
            <div className="modal-header">
                <h5 className="modal-title">Delete Confirmation</h5>
                <button type="button" className="btn-close" onClick={()=>setShow(false)}></button>
            </div>
            <div className="modal-body">
                Are you sure you want to delete this Tool?
            </div>
            <div className="modal-footer">
                <button
                    onClick={()=>setShow(false)}
                    type="button"
                    className="btn btn-secondary">Cancel</button>
                <button
                    onClick={()=>deleteTool()}
                    type="button"
                    className="btn btn-danger">I'm sure!</button>
            </div>
        </Modal>
    )

});

export {ToolDeleteModal};