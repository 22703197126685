import axios from "axios";
import {BASE_API_URL} from "../common/Constants";
import {authHeader, fileUploadHeader} from "./base.service";

const API_URL = BASE_API_URL + '/tools'

class ToolService {
    createTool(formData) {
        return axios.post(API_URL, formData, {headers: fileUploadHeader()})
    }

    editTool(formData, toolId) {
        return axios.put(API_URL + '/' + toolId, formData, {headers: fileUploadHeader()})
    }

    deleteTool(tool) {
        return axios.delete(API_URL + '/' + tool.id, {headers: authHeader()});
    }

    getAllTools(pageNumber, pageSize) {
        return axios.get(API_URL + '?page=' + pageNumber + '&pageSize=' + pageSize);
    }

    getToolsByCategoryId(categoryId, pageNumber, pageSize) {
        return axios.get(API_URL + '/category/' + categoryId+ '?page=' + pageNumber + '&pageSize=' + pageSize);
    }

    getToolById(toolId) {
        return axios.get(API_URL + '/' + toolId);
    }

}

export default new ToolService();