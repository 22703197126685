// function ToolsAdminPage();
import {useEffect, useRef, useState} from "react";
import './Admin.css'
import {Link, NavLink, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {clearCurrentUser, tokenExpiredHandler} from "../../store/actions/user";
import {clearTokens} from "../../store/actions/tokens";
import Category from "../../models/category";
import CategoryService from "../../services/category.service";
import {CategoryCreateModal} from "../../components/CategoryCreateModal";
import {CategoryEditModal} from "../../components/CategoryEditModal";
import {CategoryDeleteModal} from "../../components/CategoryDeleteModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFolderOpen} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {Pagination} from "../../components/Pagination";
import {DEFAULT_PAGE_SIZE} from "../../common/Constants";


const CategoriesAdminPage = () => {

    const{t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const logout = () => {
        dispatch(clearCurrentUser())
        dispatch(clearTokens())
        navigate('/login')
    }

    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(new Category(0,'',''))
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    //pagination
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);


    const createComponent = useRef();
    const editComponent = useRef();
    const deleteComponent = useRef();

    useEffect( () => {
        CategoryService.getAllCategories(pageNumber,pageSize).then((response) => {
            setCategoryList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        });
    }, []);

    const paginate =(pageNumber) => {
        CategoryService.getAllCategories(pageNumber,pageSize).then((response) => {
            setCategoryList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        });
    }

    const createCategoryRequest = () => {
        setSelectedCategory(new Category('', '', 0, 0, 0))
        createComponent.current?.showCategoryCreateModal();
    };

    const editCategoryRequest = (category) => {
        setSelectedCategory(category);
        editComponent.current?.showCategoryEditModal();
    }
    const deleteCategoryRequest = (category) => {
        setSelectedCategory(category);
        deleteComponent.current?.showCategoryDeleteModal();
    }

    const createCategoryWatcher = (category) => {
        const newList = categoryList.concat(category);
        setCategoryList(newList)
    };

    //very strange, does not work without creating new list and doing concat.
    const editCategoryWatcher = (category) => {
        const index = categoryList.findIndex((t) => t.id === category.id);
        categoryList[index] = category;
        const newList = categoryList.concat();
        setCategoryList(newList)
    };

    const deleteCategory = async () => {
        CategoryService.deleteCategory(selectedCategory).then(_ => {
            setCategoryList(categoryList.filter(x => x.id !== selectedCategory.id))
            setSuccessMessage('Category successfully deleted');
            setErrorMessage('');
        }).catch((err) => {
            if(err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('ConstraintViolationException')) {
                    setErrorMessage(t('error_delete_category_constrains'));
                    setSuccessMessage('');
                }
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
            }
            else if(err.response.data.status === 403){
                setErrorMessage(t('error_403'))
            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err)
            }

        });
    };

    return (
        <div className="height-adjust container">
            <ul className="border-top col-xxl-6 col-xl-7 col-lg-8 col-sm-12 col-md-10 rounded-bottom mx-auto admin-bar nav nav-tabs justify-content-center bg-dark">
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/tools">{t('tools_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link to="/admin/categories" className="nav-link text-dark fw-bold active " aria-current="page" >{t('categories_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/customers">{t('customers_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/orders">{t('orders_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/users">{t('users_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/">{t('overview_tab')}</Link>
                </li>
            </ul>
            <div className="p-5">
                {errorMessage &&
                    <div className="alert alert-danger">
                        {errorMessage}
                    </div>
                }
                {successMessage &&
                    <div className="alert alert-success">
                        {successMessage}
                    </div>
                }
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-6">

                                <h3><FontAwesomeIcon icon={faFolderOpen} className="ms-auto me-2"/>{t('table_name_categories')}</h3>
                            </div>

                            <div className="col-6 text-end">
                                <button className="btn btn-primary" onClick={() => createCategoryRequest()}>
                                    {t('button_create_category')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table table-striped text-center">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t('header_name')}</th>
                                <th className="text-center" scope="col">{t('header_actions')}</th>

                            </tr>
                            </thead>
                            <tbody>

                            {categoryList.map((item, ind) =>
                                <tr key={item.id}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.name}</td>

                                    <td className="w-25">
                                        <button className="btn btn-primary me-1"
                                        onClick={() => editCategoryRequest(item)}>
                                            {t('button_edit')}
                                        </button>
                                        <button
                                            onClick={() => deleteCategoryRequest(item)}
                                            className="btn btn-danger">
                                            {t('button_delete')}
                                        </button>
                                        <NavLink to={"/admin/categories/"+item.id+"/tools"}
                                                 className="ms-5 btn btn-warning">
                                            {t('button_tools')}
                                        </NavLink>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {/*PAGINATION*/}
                        <Pagination pageSize={pageSize} totalItems={totalItems} paginate={paginate}/>
                        <div className='text-start'>
                            {totalPages > 1 &&
                                <div className='mx-2 d-inline-block'>{t('pagination_page')+pageNumber}</div>
                            }
                            <div className='mx-2 d-inline-block'>{t('pagination_total')+totalItems}</div>
                        </div>
                        {/*PAGINATION*/}
                    </div>
                </div>
            </div>
            <CategoryCreateModal ref={createComponent} category={selectedCategory} onCreated={(p) => createCategoryWatcher(p)}/>
            <CategoryEditModal ref={editComponent} category={selectedCategory} onEdited={(p) => editCategoryWatcher(p)}/>
            <CategoryDeleteModal ref={deleteComponent} onConfirmed={(p) => deleteCategory()}/>
        </div>
    );

};

export {CategoriesAdminPage};