export default class Customer {
    constructor(id,name,lastName,email,discount,phone,company,createdAt,createdBy,lastUpdatedAt, lastUpdatedBy,enabled) {
        this.id = id;
        this.name = name;
        this.lastName= lastName;
        this.email = email;
        this.discount = discount;
        this.phone = phone;
        this.company = company;
        this.createdAt = createdAt;
        this.createdBy = createdBy;
        this.lastUpdatedAt = lastUpdatedAt;
        this.lastUpdatedBy= lastUpdatedBy;
        this.enabled = enabled;
    }
}