// function ToolsAdminPage();
import {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import './Admin.css'
import {useDispatch} from "react-redux";
import {tokenExpiredHandler} from "../../store/actions/user";
import User from "../../models/user";
import UserService from "../../services/user.service";
import {faHandshake} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserEnableModal} from "../../components/UserEnableModal";
import {UserDeleteModal} from "../../components/UserDeleteModal";
import {useTranslation} from "react-i18next";
import {DEFAULT_PAGE_SIZE} from "../../common/Constants";
import {Pagination} from "../../components/Pagination";


const UserEnableAdminPage = () => {

    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(new User(0, '', '', 0, 0, 0, '', "", 0));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    //pagination
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);


    const enableComponent = useRef();
    const deleteComponent = useRef();

    const{t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        UserService.getNotEnabledUsers(pageNumber, pageSize).then((response) => {
            setUserList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty("error_message")) {
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }, []);

    const paginate =(pageNumber) => {
        UserService.getNotEnabledUsers(pageNumber, pageSize).then((response) => {
            setUserList(response.data.content);
            setTotalItems(response.data.totalElements);
            setPageSize(response.data.pageable.pageSize);
            setPageNumber(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
        }).catch((err) => {
            if(err.response.data.hasOwnProperty("error_message")) {
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch);
                    setSuccessMessage(t('success_refresh_access_token_reload'));
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        });
    }

    const enableUserRequest = (user) => {
        setSelectedUser(user);
        enableComponent.current?.showUserEnableModal();
    }
    const deleteUserRequest = (user) => {
        setSelectedUser(user);
        deleteComponent.current?.showUserDeleteModal();
    }

    const enableUserWatcher = (user) => {
        setUserList(userList.filter(x => x.id !== selectedUser.id))
        setSuccessMessage('User Enabled Successfully. Email notification sent!')
    };

    const deleteUser = async () => {
        UserService.deleteUser(selectedUser).then(_ => {
            setUserList(userList.filter(x => x.id !== selectedUser.id))
            setSuccessMessage('User successfully deleted');
            setErrorMessage('');
        }).catch((err) => {
            if (err.response.data.hasOwnProperty("error_message")) {
                if (err.response.data.error_message.includes('ConstraintViolationException')) {
                    setErrorMessage(t('error_delete_user_constrains'))
                    setSuccessMessage('');
                }
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
            }
            else if (err.response.data.status === 403) {
                setErrorMessage(t('error_403'))

            } else {
                setErrorMessage(t('unexpected_error_action'));
                console.log(err);
            }

        });
    };
    return (
        <div className="height-adjust container">
            <ul className="border-top col-xxl-6 col-xl-7 col-lg-8 col-sm-12 col-md-10 rounded-bottom mx-auto admin-bar nav nav-tabs justify-content-center bg-dark">
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/tools">{t('tools_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link to="/admin/categories" className="nav-link text-light" aria-current="page" >{t('categories_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/customers">{t('customers_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/orders">{t('orders_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-dark fw-bold active" to="/admin/users">{t('users_tab')}</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-light" to="/admin/">{t('overview_tab')}</Link>
                </li>
            </ul>
            <div className="p-5">
                {errorMessage &&
                    <div className="alert alert-danger">
                        {errorMessage}
                    </div>
                }
                {successMessage &&
                    <div className="alert alert-success">
                        {successMessage}
                    </div>
                }
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-6">
                                <h3><FontAwesomeIcon icon={faHandshake} className="ms-auto me-2 category-icon"/>
                                    {t('table_name_registration_requests')}
                                </h3>
                            </div>
                            <div className="col-6 text-end">
                                <Link to="/admin/users" className="mx-2 btn btn-primary">
                                    {t('button_back_to_users')}
                                </Link>
                            </div>
                            <div className="col-6 text-end">
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <table className="table table-striped text-center">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">{t('header_username')}</th>
                                <th scope="col">{t('header_email')}</th>
                                <th scope="col">{t('header_created_date')}</th>
                                <th className="text-center" scope="col">{t('header_actions')}</th>
                            </tr>
                            </thead>
                            <tbody>

                            {userList.map((item, ind) =>
                                <tr key={item.id}>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.username}</td>
                                    <td>{item.email}</td>
                                    <td>{(item.createdAt).substring(0, 10) + " | " +(item.createdAt).substring(11, 16)}</td>

                                    <td className="w-25">
                                        <button className="btn btn-primary me-1"
                                                onClick={() => enableUserRequest(item)}>
                                            {t('button_enable')}
                                        </button>
                                        <button
                                            onClick={() => deleteUserRequest(item)}
                                            className="btn btn-danger">
                                            {t('button_delete')}
                                        </button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        {/*PAGINATION*/}
                        <Pagination pageSize={pageSize} totalItems={totalItems} paginate={paginate}/>
                        <div className='text-start'>
                            {totalPages > 1 &&
                                <div className='mx-2 d-inline-block'>{t('pagination_page')+pageNumber}</div>
                            }
                            <div className='mx-2 d-inline-block'>{t('pagination_total')+totalItems}</div>
                        </div>
                        {/*PAGINATION*/}
                    </div>
                </div>
            </div>
            <UserEnableModal ref={enableComponent} user={selectedUser} onEnabled={(p) => enableUserWatcher(p)}/>
            <UserDeleteModal ref={deleteComponent} onConfirmed={(p) => deleteUser()}/>
        </div>
    );

};

export {UserEnableAdminPage};