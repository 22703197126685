import {forwardRef, useImperativeHandle, useState} from "react";
import {Modal} from 'react-bootstrap'
import {useTranslation} from "react-i18next";
import Payment from "../models/payment";
import PaymentService from "../services/payment.service";
import {useParams} from "react-router-dom";
import {tokenExpiredHandler} from "../store/actions/user";
import {useDispatch} from "react-redux";

const PaymentCreateModal = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        showPaymentCreateModal() {
            setTimeout(() => {
                setShow(true);
            }, 0)
        }
    }));

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [payment, setPayment] = useState(new Payment(0, 0, 0, '',''));
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [show, setShow] = useState(false);

    const paymentMethods = [{name: t('payment_method_bank_transfer'), value: "BANK_TRANSFER"},
        {name: t('payment_method_cash'), value: 'CASH'},
        {name: t('payment_method_credit'), value: 'CREDIT'},
        {name:t('payment_method_refund'), value:'REFUND'}];

    let {orderId} = useParams();

    const showError = (message) => {
        setErrorMessage(message)
        setTimeout(() => {
            setErrorMessage('')
        }, 5000);
    }

    const savePayment = (e) => {
        payment.rentalOrderId=orderId;
        e.preventDefault()
        setSubmitted(true)
        if (!payment.amount || !payment.rentalOrderId) {
            return;
        }
        PaymentService.createNewPayment(payment).then(response => {
            //...
            props.onCreated(response.data);
            setShow(false);
            setSubmitted(false);

        }).catch((err) => {
            if(err.response.data.hasOwnProperty('error_message')){
                console.log(err.data);
                if(err.response.data.error_message.includes('InvalidPaymentAmountException')){
                    showError(t('error_order_payment_exceed'));
                }
                if(err.response.data.error_message.includes('Refund cannot exceed total payment')){
                    showError(t('error_order_refund_exceed'));
                }
                if(err.response.data.error_message.includes('Note is required for a refund')){
                    showError(t('error_order_refund_note_required'));
                }
                if(err.response.data.error_message.includes('The Token has expired')){
                    tokenExpiredHandler(dispatch)
                    setSuccessMessage(t('success_refresh_access_token'));
                    setErrorMessage('')
                }
            }
            else if (err.response.data.status === 403) {
                showError(t('error_403'))

            } else {
                showError(t('unexpected_error_action'));
            }
            console.log(err);
        });
    };

    //<input name="" onChange=(event) => handleChange(e)
    const handleChange = (e) => {
        const {name, value} = e.target;

        setPayment((prevState => {
            return {
                ...prevState,
                [name]: value
            }
        }));
    };

    const closeButtonAction = () => {
        setShow(false)
    }
    const getPaymentMethod =(payment) => {
        let method = paymentMethods.find(x => x.value === payment.paymentMethod);
        return method.name;
    }


    return (
        <Modal show={show}>

            <form onSubmit={(e) => savePayment(e)}
                  noValidate
                  className={submitted ? 'was-validated' : ''}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t('order_payment_modal_title')}</h5>
                    <button type="button" className="btn-close" onClick={() => setShow(false)}/>
                </div>

                <div className="modal-body">

                    {errorMessage &&
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    }
                    {successMessage &&
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    }
                    {/*Name input*/}

                    <div className="mt-3"><label htmlFor="paymentMethod">{t('payment_select_payment_method_label')}</label>
                        <select
                            name="paymentMethod"
                            className="form-select"
                            value={payment.paymentMethod}
                            onChange={(e) => handleChange(e)}
                        >
                            <option value="">{t('payment_select_payment_method')}</option>
                            {paymentMethods.map(({name,value}) =>
                                <option value={value}>{name}</option>
                            )}
                        </select>

                    </div>
                    <div className="form-group col-12"><label htmlFor="amount">{t('payment_payment_amount_label')}</label>
                        <input
                            type="number"
                            name="amount"
                            placeholder={t('payment_amount_placeholder')}
                            className="form-control"
                            value={payment.amount}
                            required
                            min="1"
                            max="10000"
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    {payment.paymentMethod === 'REFUND' &&
                        <div className="form-group col-12"><label htmlFor="note">{t('payment_refund_reason')}</label>
                        <textarea
                        type="textarea"
                        name="note"
                        placeholder={t('payment_refund_reason_placeholder')}
                        className="form-control"
                        value={payment.note}
                        required
                        min="1"
                        max="200"
                        onChange={(e) => handleChange(e)}
                        />
                        </div>
                    }

                </div>

                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => closeButtonAction()}>{t('button_close')}
                    </button>
                    <button type="submit" className="btn btn-primary">{t('button_confirm')}</button>
                </div>
            </form>
        </Modal>
    )
});

export {PaymentCreateModal};