export default class User {
    constructor (id,username,email,firstName,lastName,phone,enabled,role,createdAt,lastUpdatedBy,updatedAt){
        this.id = id;
        this.username = username;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phone = phone;
        this.enabled = enabled;
        this.role = role;
        this.createdAt = createdAt;
        this.lastUpdatedBy = lastUpdatedBy;
        this.updatedAt = updatedAt;
    }
}