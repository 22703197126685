import axios from "axios";
import {BASE_API_URL} from "../common/Constants";
import {authHeader, fileUploadHeader} from "./base.service";

const API_URL = BASE_API_URL + '/payments'

class CustomerService {
    createNewPayment(payment) {
        return axios.post(API_URL, payment, {headers: authHeader()});
    }

    getPaymentsByOrderId(orderId) {
        return axios.get(API_URL + '/order/' + orderId, {headers: authHeader()});
    }

}

export default new CustomerService();