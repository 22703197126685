import {CLEAR_CURRENT_USER, SET_CURRENT_USER} from "../types";
import {clearTokens, setTokens} from "./tokens";
import jwt_decode from "jwt-decode";
import AuthenticationService from "../../services/authentication.service";

export const setCurrentUser = (user) => {
    return {
        type:SET_CURRENT_USER,
        payload:user,
    };
};
export const clearCurrentUser = () => {
    return {
        type:CLEAR_CURRENT_USER,
    };
};

// export function runLogoutTimer(dispatch) {
//     setTimeout(()=>{
//         dispatch(clearCurrentUser())
//         dispatch(clearTokens())
//     },ACCESS_TOKEN_DURATION)
// }

export function tokenExpiredHandler(dispatch) {
    if(localStorage.getItem("tokens")) {
        var now = new Date();
        var tokens = JSON.parse(localStorage.getItem('tokens'));
        var access_token = tokens.access_token;
        var accessTokenExpireDate = new Date(jwt_decode(access_token, true).exp*1000);

        if (accessTokenExpireDate < now) {
            var refresh_token = tokens.refresh_token;
            var refreshTokenExpireDate = new Date(jwt_decode(refresh_token, true).exp*1000);
            if(refreshTokenExpireDate>now) {
                AuthenticationService.refreshToken().then(response => {
                    dispatch(setTokens(response.data));
                });
            } else {
                dispatch(clearCurrentUser());
                dispatch(clearTokens())
            }
        }
    }else {
    }

}